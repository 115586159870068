<template>
  <div class="tab-pane fade active show" id="redirect_screen" role="tabpanel">
    <div class="row g-xxl-9">
      <div class="col-md-9 col-lg-9" id="redirect_left_div">
        <div class="card card-xxl-stretch mb-5 mb-xl-10 border-rounded">
          <div class="card-header card-header-stretch">
            <div class="card-title">
              <h3>Exit Screens</h3>
            </div>
            <div class="card-toolbar redirect-card">
              <ul class="nav nav-stretch fs-6 fw-bold nav-line-tabs border-transparent cursor-pointer" role="tablist" v-if="quiz && quiz.redirect_type == 'qualification'">
                <li class="nav-item text-capitalize" role="presentation" v-for="(q, index) in qualifications" :key="index" @click="setExitRedirectData('qualification',q,index)">
                  <a
                    class="nav-link text-active-primary me-4 redirect-tab" :class="selectedIndex == index ? 'active' : ''">{{ q.name }}
                  </a>
                </li>
              </ul>
              <!-- <ul class="nav nav-stretch fs-6 fw-bold nav-line-tabs border-transparent" role="tablist" v-if="quiz && quiz.redirect_type == 'based_on_score'">
                <li class="nav-item text-capitalize" role="presentation" v-for="(score, index) in quiz.scores" :key="index">
                  <a
                    class="nav-link text-active-primary me-4 redirect-tab">{{ score.score }}
                  </a>
                </li>
              </ul> -->
              <ul class="nav nav-stretch fs-6 fw-bold nav-line-tabs border-transparent cursor-pointer" role="tablist" v-if="quiz && quiz.redirect_type == 'based_on_answer'">
                <li class="nav-item text-capitalize" role="presentation" v-for="(input, index) in inputs" :key="index"  @click="setExitRedirectData('based_on_answer',input,index)">
                  <a
                    class="nav-link text-active-primary me-4 redirect-tab" :class="selectedIndex == index ? 'active' : ''">{{ input.field_label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body" :class="disableCard ? 'disable-card' : ''">
            <!-- <pre class="text-dark">{{ exitRedirectsData }}</pre>
            <pre class="text-dark">{{ questionsData }}</pre> -->
            <!-- <pre class="text-dark">{{ redirect }} </pre> -->
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="fs-6 text-dark" v-if="qualifications[selectedIndex].type != 'score'"> {{ description }} </div>
                    <div class="fw-bolder text-dark mt-3" v-if="quiz.redirect_type == 'based_on_answer' && quiz.redirect_question_id != null"> "{{ selectedInput }}"</div>
                  </div>
                </div>
                <div class="fv-row mt-5 mb-10 col-5" v-if="qualifications[selectedIndex].type != 'score'">
                  <label class="form-label fs-6 fw-bolder text-dark"
                    >Select Action:</label
                  >
                  <select
                    name="redirect_action"
                    v-model="redirect.action"
                    class="redirect_action form-select fw-bold"
                  >
                    <option
                      v-for="(a, index) in actionType"
                      :key="index"
                      :value="a.value"
                    >
                      {{ a.label }}
                    </option>
                  </select>
                </div>
                <div class="mt-5" v-show="redirect.action == 'message'">
                  <div class="flex gap-8 flex-col" v-show="redirect.type != 'score'" >
                    <div
                    id="redirect_editor"
                    >
                  </div>
                    <div
                    id="redirect_editor2"
                    >
                  </div>
                </div>
                <div class="flex gap-8 flex-col" v-show="redirect.type == 'score'">
                  <div
                  id="redirect_editor_score" 
                  >
                </div>
                  <div
                  id="redirect_editor_score2" 
                  >
                </div>
              </div>
                  <!-- <textarea
                    class="summernote"
                    name="message"
                    id="redirect_editor"
                  >
                  </textarea> -->
                </div>
                <div class="fv-row mt-5 mb-10 col-5" v-if="redirect.action == 'custom_url'">
                      <label class="form-label fs-6 fw-bolder text-dark">Redirect URL</label>
                      <input type="text" name="redirect_url" class="form-control" v-model="redirect.redirect_url" placeholder="Enter redirect URL">
                  </div>
              </div>
            </div>
            <div class="card-footer d-flex border-0 justify-content-end pb-0 px-0">
              <button
                id="redirect-submit-btn"
                type="button"
                data-type="standard"
                class="btn btn-primary btn-sm"
                @click="updateRedirect"
                :disabled="disableButton"
              >
                Update
              </button>
            </div>
            <!-- <button type="button" class="btn btn-primary btn-sm red-submit-btn" v-if="redirect.action == 'custom_url' " @click="updateRedirectUrl">Update</button> -->
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-12" id="redirect-option" v-if="redirect.type != 'score'">
        <div class="card card-xxl-stretch mb-5 mb-xl-10 border-rounded">
          <div class="card-header">
            <div class="card-title">
              <h3>Exit Options</h3>
            </div>
          </div>
          <div class="card-body pt-0 pb-1 px-5">
            <p class="text-gray-800 fw-bold mb-4 mt-9">Select Redirect Type:</p>
            <p class="text-gray-500 fw-bold mb-4 mt-7">Select the type of redirect that you would like to do happen when lead is submitted</p>
            <div>
               <label class="col-form-label fw-bold fs-6 d-flex justify-content-between align-items-center">Select Type</label>
                <select name="country" class="form-select fw-bold select2-hidden-accessible" @change="changeRedirectType" v-model="quiz.redirect_type">
                    <option :value="null">Select Type</option>
                    <option v-for="(type,index) in exitType" :key="index" :value="type.value">{{type.label}}</option>
                </select>
            </div>
            <div class="mt-5" v-if="quiz.redirect_type == 'based_on_answer'">
               <label class="col-form-label fw-bold fs-6 d-flex justify-content-between align-items-center">Select Question</label>
                <select name="country" class="form-select fw-bold select2-hidden-accessible" v-model="quiz.redirect_question_id" @change="setQuestionInputs">
                    <option :value="null">Select Question</option>
                    <option v-for="(question,index) in questionsData" :key="index" :value="question.id">{{question.question}}</option>
                </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Model :modaltitle="modaltitle" @close="closemodel()" v-if="showmodal">
    <template #body>
      <form method="post" @submit.prevent="addButton()">
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-10 fv-row">
                <label
                  for="exampleFormControlInput1"
                  class="required form-label"
                  >Label On Button</label
                >
                <input
                  type="text"
                  class="form-control form-control-solid"
                  id="editor_button_text"
                  name="editor_button_text"
                  v-model="v$.button.label.$model"
                   :class="{
                    'is-invalid custom-border': v$.button.label.$errors.length,
                  }"             
                  placeholder="Enter Button Text"
                />     
                <span
                  class="invalid-feedback"
                  role="alert"
                  v-for="(error, index) of v$.button.label.$errors"
                  :key="index"
                >
                  <strong>Label is required</strong>
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-10 fv-row">
                <label
                  for="exampleFormControlInput1"
                  class="required form-label"
                  >Button URL</label
                >
                <input
                  type="text"
                  class="form-control form-control-solid"
                  id="editor_button_url"
                  name="editor_button_url" 
                  v-model="v$.button.url.$model"
                   :class="{
                    'is-invalid custom-border': v$.button.url.$errors.length,
                  }"
                  placeholder="Enter Button URL"
                />
                <span
                  class="invalid-feedback"
                  role="alert"
                  v-for="(error, index) of v$.button.url.$errors"
                  :key="index"
                >
                  <strong>{{error.$validator == 'url' ? "Please enter valid url" : "Url is required"}}</strong>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <span class="">
                <label class="form-label fw-bold fs-7 custom-label-form">
                  Button color
                </label>
                <div class="color-picker d-flex">
                  <input
                    type="text"
                    class="text-gray-600"
                    v-model="button.color"
                    id="editor_button_color"
                  />
                  <input
                    type="color"
                    v-model="button.color"
                    id="editor_button_colorpicker"
                    name="button_color"
                  />
                </div>
              </span>
            </div>
            <div class="col-lg-6 col-sm-12">
              <span class="">
                <label class="form-label fw-bold fs-7 custom-label-form">
                  Button Text color
                </label>
                <div class="color-picker d-flex">
                  <input
                    type="text"
                    class="text-gray-600"
                    v-model="button.text_color"
                    id="editor_button_text_color"
                  />
                  <input
                    type="color"
                    id="editor_button_text_colorpicker"
                    name="button_text_color"
                    v-model="button.text_color"
                  />
                </div>
              </span>
            </div>
          </div>
          <hr />
          <div class="fw-bold fs-7 mb-2">Preview</div>
          <div class="border d-flex justify-content-center align-items-center">
            <a id="preview-button" class="preview_button" :style="{backgroundColor:button.color,color:button.text_color}">{{button.label}}</a>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            @click="closemodel()"
          >
            Close
          </button>
          <button type="submit" class="btn btn-primary">Save changes</button>
        </div>
      </form>
    </template>
  </Model>
</template>
<script>
let redirect_editor = "";
let redirect_editor2 = "";
let redirect_editor_score = "";
let redirect_editor_score2 = "";
import Model from "@/components/custom-components/model.vue";
import { required,url} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { defineComponent, toRefs } from "vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {qualifications,message} from '@/core/helpers/staticExitRedirect'

export default defineComponent({
  name: "Redirect",
  components: {
    Model,
  },
  props: {
    quizData: {
      type: Object,
    },
  },
  setup(props) {
    const toast = useToast();
    const {quizData: quiz} = toRefs(props);
    return { toast,quiz,v$: useVuelidate()};
  },
  validations() {
    return {
        button: {
        label: {required},
        url: {required,url},
        color: {required},
        text_color: {required},
      }
    };
  },
  data() {
    return {
      showmodal: false,
      modaltitle: "CTA Button",
      redirect: {
        id: null,
        unique_id: Math.floor(Math.random() * Date.now()),
        quiz_id : this.quiz.id,
        redirect_type : this.quiz.redirect_type,
        type : 'standard',
        action:"message",
        message : null,
        bottom_message : null,
        redirect_url : null,
        question_id : null,
        input_id : null
      },
      url: "",
      selected_file: "",
      quizId: this.$route.params.id,
      abc: "",
      button: {
        label: "Continue",
        url: "",
        color: "#88D4FA",
        text_color: "#0aa7f5",
      },
      actionType: [
        {
          value: "custom_url",
          label: "Custom Url",
        },
        {
          value: "message",
          label: "Message",
        },
      ],
      exitType: [
        {
          value: "qualification",
          label: "Qualified/Disqualified",
        },
        // {
        //   value: "based_on_score",
        //   label: "Based On Score",
        // },
        {
          value: "based_on_answer",
          label: "Based On Answer",
        },
      ],
      inputs : [],
      qualifications : [
        {
          type : 'standard',
          name : 'Standard',
        },
        {
          type : 'qualified',
          name : 'Qualified',
        },
        {
          type : 'disqualified',
          name : 'Disqualified',
        },
        {
          type : 'score',
          name : 'Score',
        },
      ],
      defaultQualifications : qualifications,
      defaultMessage : message,
      selectedIndex: 0,
      description : 'Select the action that you want to happen after the lead is submitted with a standard redirect',
      selectedInput : null,
      exitRedirectsData : [],
      disableButton : false,
      editerType : 'redirect_editor'
    };
  },
  computed: {
    ...mapGetters({
      getRedirectData: "Quiz/getRedirectData",
      getAllQuestion: 'Quiz/getAllQuestion',
    }),
    disableCard(){
      return this.quiz.redirect_type == 'based_on_answer' && this.quiz.redirect_question_id == null ? true : false
    },
  },
  methods: {
    ...mapActions({
      getRedirectScreen: "Quiz/getRedirectScreen",
      updateRedirectScreenUrl:"Quiz/updateRedirectScreenUrl",
      updateRedirectData:"Quiz/updateRedirect",
      getallQuestionAction:'Quiz/getallQuestionAction',
      getPowerQuestions:'Quiz/getPowerQuestions',
      updateRedirectSetting:'Quiz/updateRedirectSetting',
      saveExitRedirect:'Quiz/saveExitRedirect',
    }),
    closemodel() {
      this.showmodal = false;
    },
    setRedirectData(e) {
      this.redirect = e;
      redirect_editor.setHTMLCode(this.redirect.message);
      redirect_editor.collapse(false);
      redirect_editor2.setHTMLCode(this.redirect.bottom_message);
      redirect_editor2.collapse(false);
      redirect_editor_score.setHTMLCode(this.redirect.message);
      redirect_editor_score.collapse(false);
      redirect_editor_score2.setHTMLCode(this.redirect.bottom_message);
      redirect_editor_score2.collapse(false);
    },
    updateRedirect(){
      this.disableButton = true
      if(this.redirect.redirect_type == 'based_on_answer' && this.quiz.redirect_question_id){
        this.redirect.question_id = this.quiz.redirect_question_id
      }

      if (this.redirect.type == 'score') {
        this.redirect.message = redirect_editor_score.getHTMLCode()
        this.redirect.bottom_message = redirect_editor_score2.getHTMLCode()
      }else {
        this.redirect.message = redirect_editor.getHTMLCode()
        this.redirect.bottom_message = redirect_editor2.getHTMLCode()
      }
      this.saveExitRedirect(this.redirect).then((resp) => {
        if(resp.data.status == true){
          var redirectData = resp.data.data
          if(redirectData){
            var index = this.exitRedirectsData.findIndex(red => red.id === redirectData.id)
            if(index !== -1)
              this.exitRedirectsData[index] = redirectData
            else
              this.exitRedirectsData.push(redirectData)
          }
          this.toast.success("Redirect screen updated Successfully");
        }
        this.disableButton = false
      });
    },
    updateRedirectUrl(){
      let data={
        id:this.redirect.id,
        action:this.redirect.action,
        redirect_url:this.redirect.redirect_url
      }
      this.updateRedirectScreenUrl(data).then((res)=>{
          if(res.data.status==true){
              this.toast.success("Redirect screen updated successfully.");
          }
      })
    },
    openModel(editerType) {
      this.editerType = editerType
      this.button={
        label: "Continue",
        url: "",
        color: "#88D4FA",
        text_color: "#0aa7f5",
      },
      this.v$.button.$reset()
      this.showmodal = true;
    },
    addButton() {
      this.v$.button.$touch();
      if (this.v$.button.$invalid) {
        return;
      }
      var a = redirect_editor.insertRootParagraph("a");
      var div = redirect_editor.insertRootParagraph("div");
      var a2 = redirect_editor2.insertRootParagraph("a");
      var div2 = redirect_editor2.insertRootParagraph("div");
      // var aScore = redirect_editor_score.insertRootParagraph("a");
      // var divScore = redirect_editor_score.insertRootParagraph("div");
      var aScore2 = redirect_editor_score2.insertRootParagraph("a");
      var divScore2 = redirect_editor_score2.insertRootParagraph("div");
      if (this.editerType == 'redirect_editor') { 
        a.style = 'width:100%;max-width: 420px;text-align:center;margin-top:10px;margin-bottom:20px;display:inline-block;background-color:'+this.button.color+';color:'+this.button.text_color+';border-radius:5px;text-decoration: none !important; cursor: pointer;font-family: sans-serif; font-size: 22px;padding:15px 50px 15px 50px;font-weight:bold;'
        a.innerHTML= this.button.label
        a.href= this.button.url
        div.style = "display: flex;justify-content: center;align-items: center;"
        div.appendChild(a);
      }

      if (this.editerType == 'redirect_editor2') { 
        a2.style = 'width:100%;max-width: 420px;text-align:center;margin-top:10px;margin-bottom:20px;display:inline-block;background-color:'+this.button.color+';color:'+this.button.text_color+';border-radius:5px;text-decoration: none !important; cursor: pointer;font-family: sans-serif; font-size: 22px;padding:15px 50px 15px 50px;font-weight:bold;'
        a2.innerHTML= this.button.label
        a2.href= this.button.url
        div2.style = "display: flex;justify-content: center;align-items: center;"
        div2.appendChild(a2);
      }
      // aScore.style = 'width:100%;max-width: 420px;text-align:center;margin-top:10px;margin-bottom:20px;display:inline-block;background-color:'+this.button.color+';color:'+this.button.text_color+';border-radius:5px;text-decoration: none !important; cursor: pointer;font-family: sans-serif; font-size: 22px;padding:15px 0px 15px 0px;font-weight:bold;'
      // aScore.innerHTML= this.button.label
      // aScore.href= this.button.url
      // divScore.style = "display: flex;justify-content: center;align-items: center;"
      // divScore.appendChild(aScore);
      aScore2.style = 'width:100%;max-width: 420px;text-align:center;margin-top:10px;margin-bottom:20px;display:inline-block;background-color:'+this.button.color+';color:'+this.button.text_color+';border-radius:5px;text-decoration: none !important; cursor: pointer;font-family: sans-serif; font-size: 22px;padding:15px 50px 15px 50px;font-weight:bold;'
      aScore2.innerHTML= this.button.label
      aScore2.href= this.button.url
      divScore2.style = "display: flex;justify-content: center;align-items: center;"
      divScore2.appendChild(aScore2);
      this.showmodal=false
    },
    getQuestionsData(){
      this.getPowerQuestions(this.quizId).then((res) => {
        if (res.data.status == true) {
          let result = res.data.data;
          if (result.length > 0) {
            this.questionsData = result
          }
          if(this.quiz.redirect_type == 'based_on_answer'){
            this.setQuestionInputs()
          }
        }
      });
    },
    setQuestionInputs(){
      var question = this.questionsData.find(que => que.id == this.quiz.redirect_question_id)
      if(question)
      this.inputs = question.inputs
      else
      this.inputs = []
      this.setData()
      this.updateRedirectSettingData()
    },
    setExitRedirectData(type,data,index){
      if(type == 'qualification'){
        var quaData = this.exitRedirectsData.find(red => red.type == data.type)
        if(quaData){
          this.redirect = quaData
          redirect_editor.setHTMLCode(quaData.message);
          redirect_editor2.setHTMLCode(quaData.bottom_message);
          redirect_editor_score.setHTMLCode(quaData.message);
          redirect_editor_score2.setHTMLCode(quaData.bottom_message);
        }else{
          this.clearRedirectData()
          this.redirect.type = data.type
          redirect_editor.setHTMLCode(this.defaultQualifications[data.type]);
          redirect_editor2.setHTMLCode("");
          redirect_editor_score.setHTMLCode(this.defaultQualifications[data.type]);
          redirect_editor_score2.setHTMLCode("");
        }
      }else if(type == 'based_on_answer'){
        var questionData = this.exitRedirectsData.find(red => red.input_id == data.id)
        if(questionData){
          this.redirect = questionData
          redirect_editor.setHTMLCode(questionData.message);
          redirect_editor2.setHTMLCode(questionData.bottom_message);
          redirect_editor_score.setHTMLCode(questionData.message);
          redirect_editor_score2.setHTMLCode(questionData.bottom_message);
        }else{
          this.clearRedirectData()
          this.redirect.redirect_type = 'based_on_answer'
          this.redirect.input_id = data.id
          redirect_editor.setHTMLCode(this.defaultMessage);
          redirect_editor2.setHTMLCode(this.defaultMessage);
          redirect_editor_score.setHTMLCode(this.defaultMessage);
          redirect_editor_score2.setHTMLCode(this.defaultMessage);
        }
      }
      this.selectedIndex = index
      this.setDescriptionData()
    },
    changeRedirectType(){
      this.selectedIndex = 0
      this.setDescriptionData()
      this.setData()
      this.updateRedirectSettingData()
    },
    setCustomEditorData(){
      /* eslint-disable */
      let buttonStatus = "";
      var self = this;
      var standardcfg = {};
      standardcfg.skin = "rounded-corner";
      standardcfg.editorResizeMode = "height";
      standardcfg.toolbarfactory_add_button = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "Add Button";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          buttonStatus = "standard";
          openModel('redirect_editor');
        };
        return btn;
      };
      standardcfg.toolbar = "mytoolbar";
      standardcfg.toolbar_mytoolbar =
        "{bold,italic,underline,forecolor,backcolor}{justifyleft,justifycenter,justifyright,justifyfull}{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
        " #{paragraphs:toggle,fontsize:toggle,inlinestyle,lineheight}" +
        '/ {cut,copy,paste,delete,find}{insertlink,insertimage,insertvideo}|{preview,code,selectall}{add_button}' +
        "#{fullscreenenter,fullscreenexit,undo,redo,togglemore}" ;
      redirect_editor = new window.RichTextEditor(
        "#redirect_editor",
        standardcfg
      );

      var standardcfg2 = {};
      standardcfg2.skin = "rounded-corner";
      standardcfg2.editorResizeMode = "height";
      standardcfg2.toolbarfactory_add_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Name]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor2.insertHTML("[Name]")
          redirect_editor2.collapse(false);
		      redirect_editor2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_button = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "Add Button";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          buttonStatus = "standard";
          openModel('redirect_editor2');
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Score]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor2.insertHTML("[Score]")
          redirect_editor2.collapse(false);
		      redirect_editor2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[ScoreName]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor2.insertHTML("[ScoreName]")
          redirect_editor2.collapse(false);
		      redirect_editor2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score_description = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Description]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor2.insertHTML("[Description]")
          redirect_editor2.collapse(false);
		      redirect_editor2.focus();
        };
        return btn;
      };
      standardcfg2.toolbar = "mytoolbar";
      standardcfg2.toolbar_mytoolbar =
        "{bold,italic,underline,forecolor,backcolor}{justifyleft,justifycenter,justifyright,justifyfull}{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
        " #{paragraphs:toggle,fontsize:toggle,inlinestyle,lineheight}" +
        '/ {cut,copy,paste,delete,find}{insertlink,insertimage,insertvideo}|{preview,code,selectall}{add_button}{add_name}{add_score}{add_score_name}{add_score_description}' +
        "#{fullscreenenter,fullscreenexit,undo,redo,togglemore}" ;

      redirect_editor2 = new window.RichTextEditor(
        "#redirect_editor2",
        standardcfg2
      );
      /* eslint-enable */
    },
    setCustomEditorDataScore(){
      /* eslint-disable */
      let buttonStatus = "";
      var self = this;
      var standardcfg = {};
      standardcfg.skin = "rounded-corner";
      standardcfg.editorResizeMode = "height";
      standardcfg.toolbarfactory_add_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Name]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score.insertHTML("[Name]")
          redirect_editor_score.collapse(false);
		      redirect_editor_score.focus();
        };
        return btn;
      };
      standardcfg.toolbarfactory_add_score = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Score]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score.insertHTML("[Score]")
          redirect_editor_score.collapse(false);
		      redirect_editor_score.focus();
        };
        return btn;
      };
      standardcfg.toolbarfactory_add_score_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[ScoreName]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score.insertHTML("[ScoreName]")
          redirect_editor_score.collapse(false);
		      redirect_editor_score.focus();
        };
        return btn;
      };
      standardcfg.toolbarfactory_add_score_description = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Description]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score.insertHTML("[Description]")
          redirect_editor_score.collapse(false);
		      redirect_editor_score.focus();
        };
        return btn;
      };
      standardcfg.toolbar = "mytoolbar";
      standardcfg.toolbar_mytoolbar =
        "{bold,italic,underline,forecolor,backcolor}{justifyleft,justifycenter,justifyright,justifyfull}{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
        " #{paragraphs:toggle,fontsize:toggle,inlinestyle,lineheight}" +
        '/ {cut,copy,paste,delete,find}{insertlink,insertimage,insertvideo}|{preview,code,selectall}{add_name}{add_score}{add_score_name}{add_score_description}' +
        "#{fullscreenenter,fullscreenexit,undo,redo,togglemore}" ;

        redirect_editor_score = new window.RichTextEditor(
        "#redirect_editor_score",
        standardcfg
      );
        


      var standardcfg2 = {};
      standardcfg2.skin = "rounded-corner";
      standardcfg2.editorResizeMode = "height";
      standardcfg2.toolbarfactory_add_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Name]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score2.insertHTML("[Name]")
          redirect_editor_score2.collapse(false);
		      redirect_editor_score2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_button = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "Add Button";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          buttonStatus = "standard";
          openModel('redirect_editor_score2');
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Score]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score2.insertHTML("[Score]")
          redirect_editor_score2.collapse(false);
		      redirect_editor_score2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score_name = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[ScoreName]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score2.insertHTML("[ScoreName]")
          redirect_editor_score2.collapse(false);
		      redirect_editor_score2.focus();
        };
        return btn;
      };
      standardcfg2.toolbarfactory_add_score_description = function () {
        var btn = document.createElement("button");
        btn.innerHTML = "[Description]";
        btn.className = "btn btn-custom";
        btn.style.cssText = "height:32px;margin:2px;padding:0px 5px";
        btn.onclick = function () {
          redirect_editor_score2.insertHTML("[Description]")
          redirect_editor_score2.collapse(false);
		      redirect_editor_score2.focus();
        };
        return btn;
      };
      standardcfg2.toolbar = "mytoolbar";
      standardcfg2.toolbar_mytoolbar =
        "{bold,italic,underline,forecolor,backcolor}{justifyleft,justifycenter,justifyright,justifyfull}{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
        " #{paragraphs:toggle,fontsize:toggle,inlinestyle,lineheight}" +
        '/ {cut,copy,paste,delete,find}{insertlink,insertimage,insertvideo}|{preview,code,selectall}{add_button}{add_name}{add_score}{add_score_name}{add_score_description}' +
        "#{fullscreenenter,fullscreenexit,undo,redo,togglemore}" ;


        redirect_editor_score2 = new window.RichTextEditor(
        "#redirect_editor_score2",
        standardcfg2
      );
        
      /* eslint-enable */
    },
    updateRedirectSettingData(){
      var payload = {
        quiz_id : this.quiz.id,
        redirect_type : this.quiz.redirect_type,
        redirect_question_id : this.quiz.redirect_question_id,
      }
      this.updateRedirectSetting(payload)
    },
    setData(){
      if(this.quiz && this.quiz.redirect_type == 'qualification'){
        var standard = this.exitRedirectsData.find(red => red.type == 'standard')
        if(standard){
          this.redirect = standard
          redirect_editor.setHTMLCode(standard.message);
          redirect_editor2.setHTMLCode(standard.bottom_message);
          redirect_editor_score.setHTMLCode(standard.message);
          redirect_editor_score2.setHTMLCode(standard.bottom_message);
        }else{
          redirect_editor.setHTMLCode(this.defaultQualifications.standard);
          redirect_editor2.setHTMLCode("");
          redirect_editor_score.setHTMLCode(this.defaultQualifications.standard);
          redirect_editor_score2.setHTMLCode("");
        }
      }else if(this.quiz && this.quiz.redirect_type == 'based_on_answer'){
        if(this.inputs && this.inputs.length){
          var firstInput = this.inputs[0]
          var questionData = this.exitRedirectsData.find(red => red.input_id == firstInput.id)
          if(questionData){
            this.redirect = questionData
            redirect_editor.setHTMLCode(questionData.message);
            redirect_editor2.setHTMLCode(questionData.message);
            redirect_editor_score.setHTMLCode(questionData.message);
            redirect_editor_score2.setHTMLCode(questionData.bottom_message);
          }else{
            this.clearRedirectData()
            this.redirect.redirect_type = 'based_on_answer'
            this.redirect.input_id = firstInput.id
            redirect_editor.setHTMLCode(this.defaultMessage);
            redirect_editor2.setHTMLCode(this.defaultMessage);
            redirect_editor_score.setHTMLCode(this.defaultMessage);
            redirect_editor_score2.setHTMLCode(this.defaultMessage);
          }
        }
      }
      this.setDescriptionData()
      redirect_editor.collapse(false);
      redirect_editor2.collapse(false);
      redirect_editor_score.collapse(false);
      redirect_editor_score2.collapse(false);
    },
    clearRedirectData(){
      this.redirect = {
        id: null,
        unique_id: Math.floor(Math.random() * Date.now()),
        quiz_id : this.quiz.id,
        redirect_type : this.quiz.redirect_type,
        type : null,
        action:"message",
        message : null,
        bottom_message : null,
        redirect_url : null,
      }
    },
    setDescriptionData(){
      if(this.redirect.redirect_type == 'based_on_answer'){
        var input = this.inputs.find(inp => inp.id == this.redirect.input_id)
        if(input)
        this.selectedInput = input.field_label
        else
        this.selectedInput = null
        this.description = `Select the action when lead submits the answer.`
      }else{
        this.description = `Select the action that you want to happen after the lead is submitted with a ${this.redirect.type} redirect`
        this.selectedInput = null
      }
    }
  },
  mounted() {
    this.setCustomEditorData()
    this.setCustomEditorDataScore()
    // this.getallQuestionAction(this.$route.params.id)
    this.getQuestionsData(this.quizId)
    window.selfF = this;
    this.getRedirectScreen(this.quizId).then((res) => {
      if (res.data.status == true) {
        let result = res.data.data;
        this.exitRedirectsData = result
        // if (result.length > 0) {
        //   this.setRedirectData(result[0]);
        // }
        this.setData()
      }
    });
  },
  beforeUnmount(){
      var edata = document.querySelector('.rte-fixed');
      if(edata){
          edata.style.display='none'
      }
  }
});
function openModel(editerType) {
  window.selfF.openModel(editerType);
}
</script>
<style scoped>
.preview_button{
    width: 100%;
    max-width: 420px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block;
    background-color: #88D4FA;
    color: #0aa7f5;
    border-radius: 5px;
    text-decoration: none !important;
    font-family: sans-serif;
    font-size: 22px;
    padding: 15px 0px 15px 0px;
    font-weight: bold;
}
.is-invalid {
    border-color: #f1416c !important;
}
.border-rounded{
    border-radius: 5px;
}
.disable-card{
  pointer-events: none;
  opacity: 0.4;
}
</style>
<style>
.rte-fixed{
  display: none;
}
/* .rte-editable{
  min-height: 300px !important;
} */

</style>