<template>
    <!-- <div class="h3 mt-8 mb-7 text-dark fw-bolder my-0 fs-3">
        Statistics
    </div> -->
    <div class="card mb-5 mb-xl-10 rounded-2">
        <!--begin::Card header-->
        <div class="card-header">
            <!--begin::Heading-->
            <div class="card-title">
                <h3>Statistics</h3>
            </div>
            <!--end::Heading-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body">
            <div class="row">
                <div class="col-md-4 col-lg-4 col-sm-12 mt-3">
                    <div class="row">
                        <div class="col-12">
                            <select class="form-control py-2" name="status" placeholder="Filter by Status" v-model="selectedQuestion">
                                <option value="all">All</option>
                                <option v-for="(q,index) in getAllQuestionData" :key="index" :value="q.id">{{q.question}}</option>
                            </select>
                            <small class="form-text text-muted"><b>Filter</b> by Specific Question</small>
                        </div>
                    </div>

                </div>
                <div class="col-md-8 col-lg-8 col-sm-12">
                    <div class="d-flex flex-wrap justify-content-end">
                        <!--begin::Stat-->
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center ">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                            transform="rotate(90 13 6)" fill="black" />
                                        <path
                                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                            fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" data-kt-countup-value="100"
                                    data-kt-countup-prefix="">{{quizData.total_view}}</div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class="fw-bold fs-7 text-gray-400">Total Views</div>
                            <!--end::Label-->
                        </div>
                        <!--end::Stat-->
                        <!--begin::Stat-->
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr065.svg-->
                                <span class="svg-icon svg-icon-3 svg-icon-danger me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1"
                                            transform="rotate(-90 11 18)" fill="black" />
                                        <path
                                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                            fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" data-kt-countup-value="50">{{quizData.entries_count}}
                                </div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class="fw-bold fs-7 text-gray-400">Entries</div>
                            <!--end::Label-->
                        </div>
                        <!--end::Stat-->
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                            transform="rotate(90 13 6)" fill="black" />
                                        <path
                                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                            fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" data-kt-countup-value="25"
                                    data-kt-countup-prefix="">{{stats.qualified_entries_count}}</div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class="fw-bold fs-7 text-gray-400">Qual ({{stats.all_entries_count > 0 ? ((stats.qualified_entries_count / stats.all_entries_count) * 100).toFixed(0) : 0}}%)</div>
                            <!--end::Label-->
                        </div>
                        <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <!--begin::Number-->
                            <div class="d-flex align-items-center">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
                                <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1"
                                            transform="rotate(90 13 6)" fill="black" />
                                        <path
                                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                            fill="black" />
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <div class="fs-3 fw-bolder text-dark" data-kt-countup="true" data-kt-countup-value="25"
                                    data-kt-countup-prefix="">{{stats.disqualified_entries_count}}</div>
                            </div>
                            <!--end::Number-->
                            <!--begin::Label-->
                            <div class="fw-bold fs-7 text-gray-400">Disqual({{stats.all_entries_count > 0 ? ((stats.disqualified_entries_count / stats.all_entries_count) * 100).toFixed(0) : 0}}%)</div>
                            <!--end::Label-->
                        </div>
                        <div class="link-icon pt-2">
                            <i class="fas fa-external-link-alt fa-lg"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-5 mb-xl-10" v-for="(aq,index) in allQuestionsInputsData" :key="index">
        <div class="card-body pb-5 pt-5" v-if="aq.type=='dropdown-question' || aq.type=='options-question' || aq.type=='yes-no-question'">
            <!--begin::Details-->
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <!--begin: Pic-->
                <div class="me-7 mb-4 mt-1">
                    <i class="fas fa-list fa-2x" aria-hidden="true"></i>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                    <!--begin::Title-->
                    <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <!--begin::User-->
                        <div class="d-flex flex-column">
                            <!--begin::Name-->
                            <div class="d-flex align-items-center">
                                <a  class="text-gray-900 text-hover-primary fs-3 fw-bolder me-1">{{aq.question}}</a>
                            </div>
                            <!--end::Name-->
                            <!--begin::Info-->
                            <div class="d-flex flex-wrap fw-bold fs-7 mb-4 pe-2">
                                <a 
                                    class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                    <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg-->
                                    <!--end::Svg Icon-->{{aq.description}}
                                </a>
                            </div>
                            <!--end::Info-->
                        </div>
                        <!--end::User-->
                        <!--begin::Actions-->
                        <div class="d-flex align-items-start">
                            <div class="d-flex align-items-center w-200px w-sm-350px flex-column mt-3">
                                <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                                    <span class="fw-bold fs-6 text-gray-400">People That Completed</span>
                                    <span class="fw-bolder fs-6">{{aq.total_completed}}%</span>
                                </div>
                                <div class="h-5px mx-3 w-100 bg-light mb-3">
                                    <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: aq.total_completed + '%' }" :aria-valuenow="aq.total_completed" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <a  class="btn btn-sm btn-light ms-10 mt-2" id="kt_user_follow_button">
                                <!--begin::Svg Icon | path: icons/duotune/arrows/arr012.svg-->
                                <span class="svg-icon svg-icon-3 d-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <path opacity="0.3"
                                            d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                                            fill="black"></path>
                                        <path
                                            d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                                            fill="black"></path>
                                    </svg>
                                </span>
                                <!--end::Svg Icon-->
                                <!--begin::Indicator-->
                                <span class="indicator-label">Entries</span>
                                <span class="indicator-progress">Please wait...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                <!--end::Indicator-->
                            </a>
                            <!--end::Menu-->
                        </div>
                        <!--end::Actions-->
                    </div>
                    <!--end::Title-->
                </div>
                <!--end::Info-->
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="d-flex flex-column ms-15">
                        <!--begin::Name-->
                        <div class="d-flex align-items-center" v-for="(c,cindex) in aq.mdata" :key="cindex">
                            <span class="display-dot bg-primary"></span>
                            <a  class="text-gray-900 fs-6 me-1 mb-2">{{c.qres}} - {{c.qcount}} ({{aq.total_count > 0 ? ((c.qcount / aq.total_count) * 100).toFixed(0) : 0}}%)</a>
                        </div>
                        <!--end::Name-->
                        <!--end::Info-->
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <CircleChart :cdata="aq.mdata"/>
                </div>

            </div>
            <!--end::Details-->
        </div>
        <div class="card-body pb-0 pt-5 mb-5" v-else>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8 d-flex p-0">
                    <i class="far fa-comment-alt fa-2x me-5 mt-2" aria-hidden="true"></i>
                    <div class="d-flex flex-column">
                        <div class="d-flex align-items-center">
                            <a  class="text-gray-900 text-hover-primary fs-3 fw-bolder me-1">{{aq.question}}</a>
                        </div>
                        <div class="d-flex flex-wrap fw-bold fs-7 mb-4 pe-2">
                            <a  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                               {{aq.description}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                    <div class="d-flex align-items-center w-200px w-sm-350px flex-column mt-3 ms-8">
                        <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span class="fw-bold fs-6 text-gray-400">People That Completed</span>
                            <span class="fw-bolder fs-6">{{aq.total_completed}}%</span>
                        </div>
                        <div class="h-5px mx-3 w-100 bg-light mb-3">
                            <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: aq.total_completed + '%' }" :aria-valuenow="aq.total_completed" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-20">
                <div class="col-sm-12 col-md-12 col-lg-8 p-0">
                    <a @click="expand(aq)" class="btn btn-sm btn-light me-2 w-90px ms-10">
                        <span class="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3"
                                    d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                                    fill="black"></path>
                                <rect x="6" y="12" width="7" height="2" rx="1" fill="black"></rect>
                                <rect x="6" y="7" width="12" height="2" rx="1" fill="black"></rect>
                            </svg></span>
                        <span class="indicator-label">{{aq.total_count}}</span>
                    </a>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mt-15">
                    <a @click="expand(aq)" class="btn btn-sm btn-light me-2 w-200px w-sm-350px ms-8">
                        <span class="indicator-label">view {{aq.total_count}} {{aq.total_count== 0 ? 'comment' : 'comments' }}  </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CircleChart from '../chart/circle.vue'
import { mapActions, mapGetters} from "vuex"
export default {
    name:"statistics-main",
    components:{
        CircleChart
    },
    data(){
        return{
            stats:{},
            getAllQuestionInputData:[],
            quiz_id:this.$route.params.id,
            selectedQuestion : 'all'
        }
    },
    props:{
        quizData:{
            type:Object
        }
    },
    computed:{
        ...mapGetters({
            getAllQuestionData:'Quiz/getAllQuestionData',
            // getAllQuestionInputData:"Quiz/getAllQuestionInputData"
        }),
        allQuestionsInputsData(){
            if(this.selectedQuestion == 'all'){
                return this.getAllQuestionInputData
            }else{
                return this.getAllQuestionInputData.filter(question => question.id == this.selectedQuestion)
            }
        }
    },
    methods:{
        ...mapActions({
            getStats:'Quiz/getStats',
            allQuestion:'Quiz/allQuestion',
            allQuestionInput:'Quiz/allQuestionInput',
            openSidebar:'Quiz/openSidebar'
        }),
        expand(e){
            this.openSidebar(e)
        }
    },
    created(){
        this.getStats(this.quiz_id).then((res)=>{
            if(res.data.status==true){
                this.stats=res.data.data
            }
        })
        this.allQuestion(this.quiz_id)
        let data={
            quiz_id:this.quiz_id
        }
        this.allQuestionInput(data).then((res)=>{
            if(res.data.status==true){
                this.getAllQuestionInputData=res.data.data
            }
        })
    }
}
</script>
