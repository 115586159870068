<template>
    <div class="box custom-tabs">
        <div @click="updateActiveTab('standard')" :class="active_tab == 'standard' ? 'active' : ''"
            class="cursor-pointer">
            Standard
        </div>
        <span style="font-size: 15px; color: gray;">|</span>
        <div @click="updateActiveTab('qualified')" :class="active_tab == 'qualified' ? 'active' : ''"
            class="cursor-pointer">
            Qualified
        </div>
        <span style="font-size: 15px; color: gray;">|</span>
        <div @click="updateActiveTab('disqualified')" :class="active_tab == 'disqualified' ? 'active' : ''"
            class="cursor-pointer">
            Disqualified
        </div>
    </div>

    <div class="tab-content mt-4" id="myTabContent">
        <div class="tab-pane fade show active" id="submissions" role="tabpanel">
            <div class="row g-xxl-9">
                <div class="col-sm-12 col-lg-9 col-md-9"
                    v-for="(automation, index) in automationData.filter(auto => auto.action == active_tab)"
                    :key="index">
                    <div class="card card-flush h-lg-100">
                        <div class="card-header mt-6">
                            <div class="card-title flex-column">
                                <h3 class="fw-bolder mb-1 text-capitalize">{{ active_tab }}</h3>
                                <div class="fs-6 fw-bold text-gray-400">It will fire this tag if lead type is {{
            active_tab }}</div>
                            </div>
                        </div>
                        <div class="card-body p-9 pt-5">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-7">
                                        <label for="tag_id" class="form-label">Tag</label>
                                        <select class="form-control" id="tag_id" v-model="quizTag[active_tab == 'standard' ? 'standard_id' : active_tab == 'qualified' ? 'qualified_id' : 'disqualified_id']"
                                            data-dropdown-parent="#tag_modal" data-placeholder="Select The tag"
                                            data-allow-clear="true">
                                            <option :value="null">Select Tags</option>
                                            <option v-for="(tag, index) in getTagsData" :key="index" :value="tag._id">
                                                {{ tag.name }} </option>
                                        </select>
                                    </div>
                                    <button type="button" class="btn btn-primary btn-sm"
                                        @click="saveIntegrationData(automation)">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-3 col-md-3"
                    v-for="(automation, index) in automationData.filter(auto => auto.action == active_tab)"
                    :key="index">
                    <div class="card card-flush h-lg-100">
                        <div class="card-header">
                            <div class="d-flex flex-wrap gap-1">
                                <h3 class="card-title fw-bolder align-items-start flex-column">Advanced Options</h3>
                                <img :src="require('@/assets/images/img/question_mark_icon.svg')">
                            </div>
                            <!-- <div class="card-toolbar">
                                <span class="svg-icon svg-icon-primary svg-icon-2x show-pointer"
                                    @click="$emit('clearSelected')">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" stroke="none" version="1.1">
                                        <g stroke="#A1A5B7" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                                fill="#A1A5B7">
                                                <rect x="0" y="7" width="16" height="2" rx="1"></rect>
                                                <rect opacity="0.3"
                                                    transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                                                    x="0" y="7" width="16" height="2" rx="1"></rect>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div> -->
                        </div>
                        <div class="card-body p-9 pt-5 border-t">
                            <div class="fs-6 fw-bold text-black w-80 text-gray-700">Since your using scoring there are
                                advanced options for your tagging</div>

                            <div class="mt-10" >
                                <div class="form-check form-check-custom form-check-solid">
                                        <input class="form-check-input w-20px h-20px" id="disqualifying_question" v-model="taggingOption" type="radio" @change="changeOptionDebounce" value="disqualifying_question">
                                        <label class="form-label m-0 ms-4 me-4" for="disqualifying_question">
                                            Disqualifying Question(s)
                                        </label>
                                </div>        
                                <div class="form-check form-check-custom form-check-solid my-6">
                                        <input class="form-check-input w-20px h-20px" id="score_qualification"  v-model="taggingOption" type="radio" @change="changeOptionDebounce" value="score_qualification">
                                        <label class="form-label m-0 ms-4 me-4" for="score_qualification">
                                            Score Qualifications
                                        </label>
                                </div>        
                                <div class="form-check form-check-custom form-check-solid">
                                        <input class="form-check-input w-20px h-20px" id="eaither_disqualification"  v-model="taggingOption" type="radio" @change="changeOptionDebounce" value="eaither_disqualification">
                                        <label class="form-label m-0 ms-4 me-4" for="eaither_disqualification">
                                            Eaither Disqualification
                                        </label>
                                </div>        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex"
import _ from "lodash";
import { useToast } from "vue-toastification";
export default defineComponent({
    name: "Automation",
    props: {
        quizData: {
            type: Object,
        },
    },
    setup() {
        const toasts = useToast();
        return { toasts };
    },
    computed: {
        ...mapGetters({
            getTagsData: 'GC/getTagsData',
            getTags: 'Auth/getTags',
        }),
    },
    data() {
        return {
            active_tab: 'standard',
            taggingOption : 'disqualifying_question',
            automationData: [
                {
                    id: null,
                    unique_id: Math.floor(Math.random() * Date.now()),
                    action: 'standard',
                    condition: null,
                    integrationType: 'tag',
                    tagId: '',
                    actionId: '',
                    actionData: [],
                    actionResponses: {},
                    isSaveIntegration: false,
                    isLoading: false
                },
                {
                    id: null,
                    unique_id: Math.floor(Math.random() * Date.now()),
                    action: 'qualified',
                    condition: null,
                    integrationType: 'tag',
                    tagId: '',
                    actionId: '',
                    actionData: [],
                    actionResponses: {},
                    isSaveIntegration: false,
                    isLoading: false
                },
                {
                    id: null,
                    unique_id: Math.floor(Math.random() * Date.now()),
                    action: 'disqualified',
                    condition: null,
                    integrationType: 'tag',
                    tagId: '',
                    actionId: '',
                    actionData: [],
                    actionResponses: {},
                    isSaveIntegration: false,
                    isLoading: false
                },
            ],
            automationDataDB: [],
            quizTag : {
                standard_id : null,
                qualified_id : null,
                disqualified_id : null ,
            }
        }
    },
    mounted() {
        this.taggingOption = this.quizData.tagging_option
        this.getTagList()
        this.getAutomationData()

        this.quizTag.standard_id = this.quizData.standard_id || null;
        this.quizTag.qualified_id = this.quizData.qualified_id || null;
        this.quizTag.disqualified_id = this.quizData.disqualified_id || null;
    },
    methods: {
        ...mapActions({
            getTagList: 'GC/getTagList',
            createIntegrationAction: 'GC/createIntegrationAction',
            pullNextAction: 'GC/pullNextAction',
            saveDirectIntegration: 'GC/saveDirectIntegration',
            saveQuizAutomation: 'Quiz/saveQuizAutomation',
            quizTagUpdate: "Quiz/quizTagUpdate",
            getAutomation: 'Quiz/getAutomation',
            getIntegrationAction: 'GC/getIntegrationAction',
            setTagData: 'Quiz/setTagData',
            changetaggingOption: 'Quiz/changetaggingOption',
        }),
        updateActiveTab(type) {
            this.active_tab = type
            this.setAutomationData()
        },
        getGCIntegrationActionId(index = 0) {
            this.createIntegrationAction().then((res) => {
                if (res.status == 200) {
                    var actionData = res.data
                    this.automationData[index].actionId = actionData._id
                    this.automationData[index].actionData = actionData.data
                }
            })
        },
        nextAction(automation, aIndex) {
            var index = this.automationData.findIndex(a => a.action == automation.action && a.condition == automation.condition)
            console.log('index', index)
            console.log('aIndex', aIndex)
            var payload = {
                actionId: automation.actionId,
                responses: automation.actionResponses
            }
            this.pullNextAction(payload).then((res) => {
                console.log('rrr', res)
                if (res.status == 200) {
                    var actionData = res.data
                    if (Object.keys(actionData).length == 0) {
                        this.automationData[index].isSaveIntegration = true
                    } else {
                        this.automationData[index].actionData.splice(aIndex + 1)
                        this.automationData[index].isSaveIntegration = false
                        this.automationData[index].actionData.push(actionData)
                    }
                } else {
                    this.toast.warning(res.data.message)
                }
            })
        },
        saveIntegrationData(automation) {
            this.saveAutomationDB(automation)
            // if(automation.integrationType == 'direct'){
            //     this.saveDirectIntegration(automation)
            // }
        },
        saveAutomationDB(automation) {
            automation.quiz_id = this.quizData.id

            let payload = {
                quiz_id: this.quizData.id,
                ...this.quizTag
            }

            this.quizTagUpdate(payload).then((res) => {
                if (res.data.status == true) {
                    this.toast.success('Automation data saved')
                }
            }).catch((error) => {
                this.toast.error(error.message);
            });
        },
        getAutomationData() {
            this.getAutomation(this.quizData.id).then((res) => {
                if (res.data.status == true) {
                    this.automationDataDB = res.data.data
                    this.setAutomationData();
                }
            })
        },
        setAutomationData() {
            this.automationData.filter(auto => auto.action == this.active_tab).map(automation => {
                var selectedAutomationIndex = this.automationData.findIndex(a => a.action == automation.action && a.condition == automation.condition)
                var dbAutomation = this.automationDataDB.find(a => a.action == automation.action && a.condition == automation.condition)
                if (dbAutomation)
                    this.setData(dbAutomation, selectedAutomationIndex)
                else
                    this.getGCIntegrationActionId(selectedAutomationIndex)
            })
        },
        setData(automation, index) {
            this.automationData[index].id = automation.id
            this.automationData[index].action = automation.action
            this.automationData[index].condition = automation.condition
            this.automationData[index].integrationType = automation.integration_type
            this.automationData[index].tagId = automation.tag_id
            this.automationData[index].actionId = automation.action_id
            if (automation.action_id) {
                this.getIntegrationAction(automation.action_id).then((res) => {
                    if (res.status == 200) {
                        this.automationData[index].actionData = res.data.data
                        this.automationData[index].actionResponses = res.data.inputData ? res.data.inputData : {}
                        this.automationData[index].isSaveIntegration = true
                    }
                })
            }
        },
        changeOptionDebounce: _.debounce(function () {
            let payload = {
                id : this.quizData.id,
                option : this.taggingOption,
            }
            this.changetaggingOption(payload)
                .then(
                    (res) => {
                        this.toasts.success(res.data.message);
                    }
                )
                .catch(
                    (error) => {
                        this.toast.error(error.message);
                    }
                )
        }),
    }
});
</script>