<template>
        <apexchart
            type="pie"
            :options="chartOptions"
            :series="series"
            height="250"
        ></apexchart>
</template>
<script>
import { defineComponent } from 'vue'
import VueApexCharts from "vue3-apexcharts";
export default defineComponent({
    components: {
        apexchart: VueApexCharts,
    },
    props:{
        cdata:{
            type:Array
        }
    },
    created(){
        // this.series=this.cdata.map(a => a.qcount)
        // this.chartOptions.labels=this.cdata.map(a => a.qres)
        this.cdata.forEach((value) => {
                this.series.push(value.qcount);
                this.chartOptions.labels.push(value.qres);
      });
        // this.data.chartOptions.labels.push(response.data[i].key);
    },
    data(){
        return{
            series:[],
            chartOptions:{
                 chart: {
                width: 400,
                type: 'pie',
            },
            labels:[],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top'
                    }
                }
            }]
            }
        }
    }
})
</script>
<style>
.apexcharts-legend{
    width: 230px;
}
</style>
