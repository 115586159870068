<template>
    <div class="tab-pane fade show active" id="welcome_screen" role="tabpanel">
            <form method="post" id="splash_welcome_screen_form" novalidate enctype="multipart/form-data">
                <div class="row g-xxl-9">
                    <div class="col-md-9 col-lg-9 col-sm-12">
                        <div class="card card-xxl-stretch mb-5 mb-xl-10 border-rounded">
                            <div class="card-header position-relative">
                                <div class="card-title">
                                    <h3>Intro Splash
                                    </h3>
                                    <div class="card-toolbar position-absolute end-0">
                                        <div class="form-check form-check-solid form-switch">
                                            <input class="form-check-input w-30px h-20px" name="welcome_screen_status" @change="updateStatus" v-model="welcome.status" type="checkbox" id="welcome_screen_status" >
                                            <label class="form-check-label" for="welcome_screen_status"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row mb-8">
                                    <div class="col-12">
                                        <!-- <div class="fs-6 text-dark d-flex justify-content-between align-items-center">In this section you have the option to provide a welcome screen to your lead before they proceed <br> with your quiz.
                                            <div class="form-check form-check-solid form-switch">
                                                    <input class="form-check-input w-45px h-30px" name="welcome_screen_status" type="checkbox" id="welcome_screen_status" {{$welcome_screen->status == 1 ? 'checked' : ''}}>
                                                    <label class="form-check-label" for="welcome_screen_status"></label>
                                                </div>
                                        </div> -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="fs-6  text-dark">In this section you have the option to provide a
                                                    welcome
                                                    screen to your lead before they proceed with your quiz.</div>
                                            </div>
                                        </div>

                                        <div class="row mt-5 main-div" id="main-div">
                                                <textarea class="summernote" id="welcome_editor" name="welcome_message"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 position-relative">
                        <div class="card card-xxl-stretch mb-5 mb-xl-10 border-rounded">
                            <div class="card-header">
                                <div class="card-title">
                                    <h3>Options</h3>
                                </div>
                            </div>
                            <div class="card-body pt-0 pb-1">
                                <div class="accordion accordion-icon-toggle mt-7" id="kt_accordion_2">
                                    
                                    <div class="mb-5">
                                        
                                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_1">
                                            <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">
                                                    
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"></path>
                                                        </g>
                                                    </svg>
                                                    
                                                </span></span>
                                            <h3 class="fs-6 fw-bold mb-0 ms-9">Screen Background</h3>
                                        </div>
                                        <div id="kt_accordion_2_item_1" class="fs-6 collapsed collapse" data-bs-parent="#kt_accordion_2">
                                            
                                            <div id="background_div">
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex mt-5">
                                                        <span class="form-check form-check-custom form-check-solid">
                                                            <input class="form-check-input w-20px h-20px" type="radio" v-model="welcome.screen_bg_type" id="screen_bg_image" value="image">
                                                            <label class="form-label m-0 ms-4 me-4" for="screen_bg_type">
                                                                Image
                                                            </label>
                                                        </span>
                                                        <span class="form-check form-check-custom form-check-solid">
                                                            <input class="form-check-input w-20px h-20px" type="radio" v-model="welcome.screen_bg_type" id="screen_bg_color" value="color">
                                                            <label class="form-label m-0 ms-4" for="screen_color">
                                                                Color
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div class="welcome_preview_div">
                                                        <img id="bg_image_preview" :src="url"  style="height:50px;width:80px"  v-if="welcome.screen_bg_type == 'image'">
                                                        </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-lg-12" v-if="welcome.screen_bg_type == 'image'" id="screen_bg_image_div">
                                                        <span class="">
                                                            <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                                Background Image
                                                            </label>
                                                             <input type="file" name="bg_image" ref="fileInput" id="bg_input"
                                                        class="form-control" @change="onFileChange"><small class="text-danger welcome-image-error"></small>
                                                        </span>
                                                    </div>
                                                    <div class="col-lg-6" v-else id="screen_bg_color_div">
                                                        <span class="">
                                                            <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                                Background color
                                                            </label>
                                                            <div class="color-picker d-flex">
                                                                <input type="text" class="text-gray-600" v-model="welcome.screen_bg_color" id="hexcolor">
                                                                <input type="color" id="colorpicker" name="screen_bg_color" v-model="welcome.screen_bg_color">
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="separator separator-solid my-6"></div>
                                    
                                    <div class="mb-5">
                                        
                                        <div class="accordion-header py-3 d-flex collapsed" data-bs-toggle="collapse" data-bs-target="#kt_accordion_2_item_3">
                                            <span class="accordion-icon"><span class="svg-icon svg-icon-primary svg-icon-xl">
                                                    
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"></path>
                                                            <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)"></path>
                                                        </g>
                                                    </svg>
                                                    
                                                </span></span>
                                            <h3 class="fs-6 fw-bold mb-0 ms-9">Start Bar</h3>
                                        </div>
                                        

                                        
                                        <div id="kt_accordion_2_item_3" class="collapse fs-6 " data-bs-parent="#kt_accordion_2">
                                            <div class="row mt-5">
                                                <div class="col-lg-6">
                                                    <span class="">
                                                        <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                            Bar color
                                                        </label>
                                                        <div class="color-picker d-flex">
                                                            <input type="text" class="text-gray-600" v-model="welcome.bar_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" id="bar_color">
                                                            <input type="color" id="bar_color_colorpicker" name="bar_color" v-model="welcome.bar_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$">
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <span class="">
                                                        <label class="form-label fw-bold  fs-7 custom-label-form ">
                                                            Bar Text color
                                                        </label>
                                                        <div class="color-picker d-flex">
                                                            <input type="text" class="text-gray-600" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="welcome.bar_text_color" id="hexcolor_1">
                                                            <input type="color" id="colorpicker_1" name="bar_text_color" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" v-model="welcome.bar_text_color">
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="card-footer d-flex border-0 justify-content-end"><button type="button" class="btn btn-primary btn-sm" id="welcome-submit-btn" @click="saveRedirect" :disabled="!this.welcome.id ? true : false">Update</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
</template>
<script>
let welcome_editor = "";
import { defineComponent, onUnmounted } from "vue";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";
export default defineComponent({
    name:"Welcome",
  props: {
    quizData: {
      type: Object,
    },
  },
  setup() {
    // onMounted(() => {
      
    // });
    onUnmounted(() => {
    //   const element = document.getElementsByClassName('rte-floatpanel-paragraphop');
    //   element.parentNode.removeChild(element);
    });
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      welcome: {},
      url: "",
      selected_file: "",
      id: this.$route.params.id,
    //   welcome_editor:'',
      content:
        '<h2 class="page_speed_1904149950 page_speed_1662472061" style="text-align: center;">This Quiz Will Only Take About 2&nbsp;Minutes To Complete</h2> <p class="page_speed_720609814 page_speed_1662472061" style="text-align: center;">Click the button below to get started!</p></br>',
    };
  },
  methods: {
    ...mapActions({
      getWelcomeData: "Quiz/getWelcomeData",
      updateStatusWelcome: "Quiz/updateStatusWelcome",
      updateWelcome: "Quiz/updateWelcome",
    }),
    saveRedirect() {
      var formdata = new FormData();
      formdata.append("id", this.welcome.id);
      formdata.append("screen_bg_type", this.welcome.screen_bg_type);
      formdata.append("screen_bg_image", this.selected_file);
      formdata.append("screen_bg_color", this.welcome.screen_bg_color);
      formdata.append("button_color", this.welcome.button_color);
      formdata.append("bar_color", this.welcome.bar_color);
      formdata.append("bar_text_color", this.welcome.bar_text_color);
      formdata.append("message", welcome_editor.getHTMLCode());
      this.updateWelcome(formdata).then(() => {
        this.toast.success("Welcome screen updated Successfully");
      });
    },
    updateStatus() {
      let data = {
        id: this.welcome.id,
        status: this.welcome.status,
      };
      this.updateStatusWelcome(data).then(() => {
        this.toast.success("Status updated Successfully");
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        if (
          file.type == "image/png" ||
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/svg" ||
          file.type == "image/svg+xml"
        ) {
          const fileSize = Math.round(file.size / 1024);
          if (fileSize >= 4096) {
            this.$refs.fileInput.value = "";
            this.$swal.fire(
              "Error!",
              "Please enter image less then 4mb",
              "error"
            );
            return;
          }
          this.selected_file = file;
          this.url = URL.createObjectURL(file);
        } else {
          this.$refs.fileInput.value = "";
          this.$swal.fire("Error!", "Please enter valid image file.", "error");
        }
      }
    },
  },
  mounted() {
        var welcomecfg = {};
        welcomecfg.skin = "rounded-corner";
        welcomecfg.class = "test-abc";
        welcomecfg.toolbar = "mytoolbar";
        welcomecfg.toolbar_mytoolbar =
            "{bold,italic,underline,forecolor,backcolor}{justifyleft,justifycenter,justifyright,justifyfull}{insertorderedlist,insertunorderedlist,indent,outdent,insertblockquote,insertemoji}" +
            " #{paragraphs:toggle,fontsize:toggle,inlinestyle,lineheight}" +
            " / {cut,copy,paste,delete,find}{insertlink,insertimage,insertvideo,insertdocument}|{preview,code,selectall}" +
            "#{fullscreenenter,fullscreenexit,undo,redo,togglemore}";
        welcome_editor = new window.RichTextEditor("#welcome_editor", welcomecfg);
        var element = document.getElementById("main-div");
        var numberOfChildren = element.getElementsByTagName("div").length;
        if (numberOfChildren == 2) {
            element.removeChild(element.getElementsByTagName("div")[0]);
        }
        this.getWelcomeData(this.id).then((response) => {
        if (response.data.status == true) {
            this.welcome = response.data.data;
            this.url = this.welcome.screen_bg_image;
            this.welcome.status = !!this.welcome.status;
            welcome_editor.setHTMLCode(this.welcome.message);
            welcome_editor.collapse(false);
        }
        });
  },
  beforeUnmount(){
    //   console.log(121212,'hello')
    //   document.getElementsByClassName("xrte-floatpanel-paragraphop").style.left='50%'
    //   let edata=document.getElementsByClassName("rte-fixed")
      var edata = document.querySelector('.rte-fixed');
      if(edata){
          console.log(edata)
          edata.style.display='none'
      }

  }
});
</script>
<style scoped>
.border-rounded{
    border-radius: 5px;
}

.collapse {
    visibility: visible !important;
}
</style>
