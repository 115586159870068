<template>
  <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
    <div class="card-header align-items-center border-0 mt-3">
       <h3 class="title-div card-title align-items-start flex-column">
            <div
                class="dropdown_card scoring_dropdown_data2 tagging_dropdown_data2"
            >
                <span
                    id="ques_drop_2"
                    class="fw-bolder text-dark fs-3 span-text question question content-editable"
                    contenteditable="true"
                    @input="(event) => onInput(event, 'question')"
                    @click="checkInput('question')"
                    @blur="setValue('question')"
                    >{{ question.question }}</span
                >
            </div>
            <div>
                <span
                    id="desc_drop_2"
                    class="text-gray-400 mt-2 fw-bold fs-6 span-text description content-editable"
                    contenteditable="true"
                    @input="(event) => onInput(event, 'description')"
                    @click="checkInput('description')"
                    @blur="setValue('description')"
                >
                    {{ question.description }}
                </span>
            </div>
        </h3>
      <div class="div-top-icon show-on-hover">
        <!-- <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Scoring"
          @click="sendActiveToMain(2)"
          ><span class="svg-icon svg-icon-3 show-pointer"
            ><i
              class="fas fa-flag"
              :class="checkScoring(question) == true ? 'text-success' : ''"
            ></i></span
        ></a>
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title=""
          @click="sendActiveToMain(4)"
          data-bs-original-title="Jump Logic"
        >
          <span class="svg-icon svg-icon-3 show-pointer">
            <i
              class="fa fa-cogs fa-lg"
              :class="checkJumpLogic(question) == true ? 'text-success' : ''"
            ></i>
          </span>
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Tagging"
          @click="sendActiveToMain(3)"
          ><span class="svg-icon svg-icon-3 show-pointer"
            ><i
              class="fa fa-tag fa-lg"
              :class="checkTagging(question) == true ? 'text-success' : ''"
            ></i></span></a
        > -->
        <a
          href="javascript:void(0)"
          class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Delete Card"
          @click="removeQuestion"
          ><span class="svg-icon svg-icon-3 show-pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                fill="black"
              ></path>
              ̦
              <path
                opacity="0.5"
                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                fill="black"
              ></path>
              <path
                opacity="0.5"
                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                fill="black"
              ></path></svg></span
        ></a>
      </div>
      <div class="div-bottom-menu show-on-hover">
        <label
          class="form-check required-checkbox p-2 me-9"
          for="hidden_checkbox"
          ><input
            v-model="question.is_hidden"
            type="checkbox"
            class="form-check-input is_required"
            name="Checkboxes1"
            id="hidden_checkbox"
            @change="update_hidden(mainIndex)"
          /><span></span>Hidden</label
        >
        <label class="form-check required-checkbox p-2"
          ><input
            type="checkbox"
            class="form-check-input is_required"
            v-model="question.is_required"
            @change="up"
            name="Checkboxes1"
          /><span></span>Required</label
        >
      </div>
    </div>
    <div class="card-body pt-5">
        <!-- <pre class="text-dark">{{ question }}</pre> -->
      <div class="row mb-8">
        <div class="col-md-6 col-sm-12">
          <div class="form-group d-flex mx-4">
            <div class="w-150px me-5">
              <span id="email_label_5" class="form-label fw-bolder email_label"
                >Rating Image</span
              >
              <select class="form-select dropdown_select" @change="updateRatingData" v-model="question.rating_type">
                <option value="star">Star</option>
                <option value="number">Number</option>
                <option value="emoji">Emoji</option>
              </select>
            </div>
            <div class="w-150px" v-if="question.rating_type != 'emoji'">
              <span id="email_label_5" class="form-label fw-bolder email_label"
                ># Ratings</span
              >
              <select class="form-select dropdown_select" @change="updateRatingData" v-model="question.rating_count">
                <option v-for="(i, index) in 10" :key="index" :value="i">{{ i }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
            <div class="rating" v-if="question.rating_type == 'star'">
                <div class="rating-label checked" v-for="(star,index) in question.rating_count" :key="index">
                    <span class="svg-icon svg-icon-1 svg-icon-3x">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z"
                        fill="currentColor"
                        ></path>
                    </svg>
                    </span>
                </div>
            </div>

            <div class="flex items-center justify-center space-x-4" v-else-if="question.rating_type == 'number'">
                <label class="number-label" :for="`rating-number-${number}`" v-for="(number,index) in question.rating_count" :key="index">
                    <input type="radio" name="rating" :id="`rating-number-${number}`" :value="number" class="rating-input">
                    <span class="flex h-[30px] w-[30px] items-center justify-center text-[15px] text-dark">{{number}}</span>
                </label>
            </div>

            <div class="flex items-end justify-center space-x-5" v-else-if="question.rating_type == 'emoji'">
                <label class="emoji-label cursor-pointer flex justify-center">
                    <input type="radio" class="hidden emoji-input" name="rating" :value="1">
                    <span class="emoji">😡</span>
                </label>
                <label class="emoji-label cursor-pointer flex justify-center">
                    <input type="radio" class="hidden emoji-input" name="rating" :value="2">
                    <span class="emoji">🙁</span>
                </label>
                <label class="emoji-label cursor-pointer flex justify-center">
                    <input type="radio" class="hidden emoji-input" name="rating" :value="3">
                <span class="emoji">😐</span>
                </label>
                <label class="emoji-label cursor-pointer flex justify-center">
                    <input type="radio" class="hidden emoji-input" name="rating" :value="4">
                <span class="emoji">🙂</span>
                </label>
                <label class="emoji-label cursor-pointer flex justify-center">
                    <input type="radio" class="hidden emoji-input" name="rating" :value="5">
                <span class="emoji">😀</span>
                </label>
            </div>
        </div>
      </div>
    </div>
    <img
      class="draggable-handle div-left-icon show-on-hover"
      :src="require('@/assets/images/img/order_drag.svg')"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      title="Drag to change order"
    />
    <h1 class="div-left-hd show-on-hover left-count-pos-40 id">
      {{ aIndex }}
    </h1>
  </div>
</template>
<script>
import _ from "lodash";
import { defineComponent, toRefs } from "vue";
import QuizMixin from "@/mixin/question.js";
import { mapActions } from "vuex";
export default defineComponent({
  mixins: [QuizMixin],
  name: "RatingQuestion",
  props: {
    questionData: {
      type: Object,
    },
    mainIndex: {
      type: Number,
    },
  },
  setup(props) {
    const { questionData: question } = toRefs(props);
    const { mainIndex: pindex } = toRefs(props);
    return {
      question,
      pindex,
    };
  },
  data() {
    return {
      aIndex: this.mainIndex + 1,
      showedit: false,
    };
  },
  methods: {
    log: function (evt) {
      window.console.log(evt);
    },
    ...mapActions({
      addInput: "Quiz/addInput",
      updateInputValue: "Quiz/addInputValue",
      deleteQuestionInput: "Quiz/deleteQuestionInput",
      updateRatingQuestion: "Quiz/updateRatingQuestion",
    }),
    addSelectArray() {
      let dropdown = {
        db_id: null,
        disqualify: false,
        field_label: "",
        field_value: "",
        html_tag_type: "dropdown",
        id: 1,
        tag_id: "",
        type: "dropdown",
        uniq_id: Math.floor(Math.random() * Date.now()),
      };
      this.question.inputs.push(dropdown);
      this.updateInput(dropdown);
      this.sendupdatedArray();
    },
    SaveSelectArray() {
      this.question.inputs = this.question.inputs.map((data) => ({
        ...data,
        field_label: data.field_value,
      }));
      this.showedit = false;
      this.sendupdatedArray();
    },
    removeSelectArray(iindex, id) {
      this.question.inputs.splice(iindex, 1);
      if (id) {
        this.deleteQuestionInput(id);
      }
      this.sendupdatedArray();
    },
    updateInput(data) {
      let sendData = {
        ...data,
        question_id: this.question.db_id,
        quiz_id: this.$route.params.id,
      };
      this.addInput(sendData).then((response) => {
        let result = response.data.data;
        const index = this.question.inputs.findIndex((object) => {
          return object.uniq_id == result.uniq_id;
        });
        if (index || index == 0) {
          this.question.inputs[index].db_id = result.db_id;
        }
      });
    },
    UpdateInputValueI: _.debounce(function (q) {
      this.updateInputValue(q);
    }, 500),
    removeQuestion() {
      let data = {
        index: this.pindex,
        id: this.question.db_id,
      };
      this.$emit("removequestion", data);
    },
    sendupdatedArray() {
      let data = {
        question: this.question,
        index: this.pindex,
      };
      this.$emit("updateMainArray", data);
    },
    updateRatingData(){
        
        let data={
            id:this.question.db_id,
            rating_count:this.question.rating_count,
            rating_type:this.question.rating_type,
        }
        this.updateRatingQuestion(data)
    },
  },
});
</script>

<style scoped>
.div-bottom-menu {
  position: absolute;
  right: 5px;
  /* top: 8px; */
  bottom: 8px;
  /* background-color: #FFFFFF; */
  padding-left: 35px;
}
.div-top-icon {
  position: absolute;
  right: 5px;
  top: 8px;
  /* bottom: 8px; */
  /* background-color: #FFFFFF; */
  padding-left: 35px;
}

.card:hover .title-div {
  width: 80% !important;
  word-break: break-word;
}
.btn.btn-bg-light {
  background-color: #f5f8fa;
}
.text-dark.span-text {
  color: #181c32 !important;
}
.form-select,
.form-control {
  background-color: #fff;
  border: 1px solid #e4e6ef;
  color: #181c32 !important;
}
.dropdown.show > .form-control.form-control-solid,
.form-control.form-control-solid:active,
.form-control.form-control-solid.active,
.form-control.form-control-solid:focus,
.form-control.form-control-solid.focus {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  color: #181c32 !important;
}
/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label {
  color: #181c32;
}
.col-form-label,
.form-label {
  color: #3f4254;
}


.rating-input {
  position: absolute;
  opacity: 0;
}

.number-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #CBD5E0;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease-in-out;
}

.number-label:hover,
.rating-input:checked + .number-label {
  border-color: #FCD34D;
}
.rating-input:checked + .number-label .active {
  border-color: #FCD34D;
}

.rating-number {
  font-size: 2rem;
  font-weight: bold;
}
.emoji{
    font-size: 30px;
}
</style>
