<template>
  <Model
    modaltitle="Video Library"
    @close="closeStockModal"
    modelSize="modal-lg"
    v-if="modalStatus"
  >
    <template #body>
      <div class="modal-body">
        <div class="row px-5">
            <!-- {{ question }} -->
          <div class="col-6">
            <div class="mb-5">
              <span for="video_duration" class="form-label fw-bolder"
                >Search: <small>(Ex. Building, Tiger, Animation)</small></span
              >
              <input
                id="video_duration"
                type="text"
                class="form-control form-control-sm form-col-6"
                @input="searchVideoDebounce"
                v-model="filter.query"
              />
            </div>
          </div>
        </div>
        <div class="row px-5">
          <div
            class="col-sm-6 col-md-3 mb-7 video-box"
            v-for="(video, index) in getVideosData"
            :key="index"
            @click="updateVideoData(video)"
          >
            <img :src="video.image" alt="Image 1" class="img-fluid rounded"/>
          </div>
        </div>
        <div class="row px-5">
            <div class="col-12">
                <button class="btn btn-sm btn-secondary me-4" v-if="filter.page > 1" @click="previousPage">Previous</button>
                <button class="btn btn-sm btn-secondary" @click="nextPage">Next</button>
            </div>
        </div>
    </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light"
          data-bs-dismiss="modal"
          @click="closeStockModal"
        >
          Close
        </button>
        <!-- <button type="submit" class="btn btn-primary">Save changes</button> -->
      </div>
    </template>
  </Model>
</template>

<script>
import QuizMixin from "@/mixin/question.js";
import { defineComponent, toRefs } from "vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Model from "@/components/custom-components/model.vue";
import { useToast } from "vue-toastification";


export default defineComponent({
  mixins: [QuizMixin],
  name: "yes-no",
  props: {
    question_data: {
      type: Object,
    },
    modal_status: {
      type: Boolean,
    },
  },
  components: {
    Model,
  },
  data() {
    return {
      filter: {
        page: 1,
        per_page: 12,
        query: "Ocean",
      },
    };
  },
  setup(props) {
    const { question_data: question } = toRefs(props);
    const { modal_status: modalStatus } = toRefs(props);
    const toast = useToast();
    return {
      question,modalStatus,toast
    };
  },
  computed: {
    ...mapGetters({
      getVideosData: "Pexels/getVideosData",
    }),
  },
  mounted() {
    console.log("stock called", this.modalStatus);
    this.searchVideos(this.filter);
  },
  methods: {
    ...mapActions({
      updateQuestionVideoUrl: "Quiz/updateQuestionVideoUrl",
      searchVideos: "Pexels/searchVideos",
    }),
    // removeQuestion() {
    //   let data = {
    //     index: this.pindex,
    //     id: this.question.db_id,
    //   };
    //   this.$emit("removequestion", data);
    // },
    closeStockModal() {
      this.$emit("closeStockModal", false);
    },
    searchVideoDebounce: _.debounce(function () {
        this.filter.page = 1
       this.searchVideos(this.filter)
    }, 500),
    updateVideoData(video){
        console.log('video',video)
        var videoUrl = video.video_files.find((v) => v.quality='hd' && v.height >= 1000 && v.height <= 1440 && v.width >= 1920 && v.width <= 2560)
        console.log('videoUrl',videoUrl)
        if(videoUrl){
            let data={
                id: this.question.id,
                video_url: videoUrl.link 
            }
            this.updateQuestionVideoUrl(data).then((response)=>{
                if(response.data.status==true){
                    this.toast.success('Video url updated')
                }
            })
        }else{
            this.toast.warning('This video not available in high Resultion,choose diffrent one')
        }
    },
    nextPage(){
        this.filter.page += 1
        this.searchVideos(this.filter)
    },
    previousPage(){
        this.filter.page -= 1
        this.searchVideos(this.filter)
    }
  },
});
</script>
<style scoped>
.video-box{
   cursor: pointer;
}
.video-box :hover{
    border: 2px solid #e49c35 !important;
}
</style>
