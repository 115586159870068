<template>
  <!-- <ul class="nav nav-tabs mt-11" id="myTab" role="tablist">
    <li class="nav-item me-1">
      <a
        class="nav-link fw-bold custom-nav"
        role="button"
        :class="active_tab==1 ? 'active' : ''"
        @click="active_tab=1"
        >Welcome</a
      >
    </li>
    <li class="nav-item me-1">
      <a
        class="nav-link fw-bold custom-nav"
        role="button"
        :class="active_tab==2 ? 'active' : ''"
        @click="active_tab=2"
        >Redirects</a
      >
    </li>
  </ul> -->

  <div class="box custom-tabs">
    <div :class="active_tab==1 ? 'active' : ''" @click="active_tab=1" class="cursor-pointer">
      Welcome
    </div>
    <span style="font-size: 15px; color: gray;">|</span>
    <div :class="active_tab==2 ? 'active' : ''" @click="active_tab=2" class="cursor-pointer">
      Redirects
    </div>
  </div>

  <div class="tab-content" id="myTabContent">
      <Welcome v-if="active_tab==1"/>
      <Redirect v-else-if="active_tab==2"/>
  </div>
</template>
<style scoped>
.rte-modern.rte-desktop.rte-toolbar-default {
  min-width: 810px !important;
}
.custom-nav {
  padding: 10px 20px 10px 20px !important;
  font-size: 13px;
  /* border: 1px solid #fff !important; */
  color: #F3F5F6;
}

.custom-top-bar.active {
  background: #fff;
  padding: 5px 10px 5px 10px;
  /* border: 1px solid #fff; */
  border-radius: 5px;
}

.custom-nav.active {
  color: #6794dc !important;
}

.main-label {
  position: relative;
}

.custom-input {
  top: 28px;
  position: absolute;
  left: 9px;
  width: 17px;
  height: 17px;
}

.plus-button {
  border-radius: 5px;
  border: 1px dashed #04c8c8;
  height: 55px;
  width: 80px;
}

.color-box {
  color: #04c8c8;
  background-color: #f3f5f6 !important;
  border: 1px solid;
  border-radius: 0px;
  border-color: #04c8c8;
}

.color-picker input[type="text"] {
  height: 38px;
  width: 70px;
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: center;
  border: 1.5px solid;
  border-right: 0px;
}

.color-picker input[type="color"] {
  height: 38px;
  width: 38px;
  padding: 0;
  border: 0;
}

.checkbox-checked {
  color: #04c8c8 !important;
  border-color: #04c8c8 !important;
  background-color: #f3f5f6 !important;
}
.ck-editor {
  width: 100vw !important;
}

.btn.btn-custom {
  color: #333333;
  border-color: #e8f0fe;
  background-color: #e8f0fe;
}

.btn-custom:hover {
  background-color: #f1f3f4;
}

/* .rte-editable{
            min-height: 750px !important;
        } */

video-container iframe {
  max-width: 100%;
  width: 465px;
  height: 260px;
}

.box {
  background-color: #343542;
  border-radius: 5px 5px 0px 0px;
  padding: 15px;
}

.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

</style>
<script>
import { defineComponent} from "vue";
import Welcome from "./redirect/WelcomeScreen.vue";
import Redirect from "./redirect/RedirectScreen.vue";
export default defineComponent({
  components: { Welcome,Redirect},
  props: {
    quizData: {
      type: Object,
    },
  },
  data() {
    return {
        active_tab:1
    }
  },
});
</script>
    