<template>
    <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
        <div class="card-header align-items-center border-0 mt-3">
            <h3 class="title-div card-title align-items-start flex-column">
                <div
                    class="yes_no_card scoring_yes_no_data_exist_439 tagging_yes_no_data_exist_439"
                >
                    <span
                        id="ques_yes_no_exist_439"
                        class="fw-bolder text-dark fs-3 span-text question content-editable"
                        contenteditable="true"
                        @input="(event) => onInput(event, 'question')"
                         @click="checkInput('question')"
                        @blur="setValue('question')"
                        >{{ question.question }}</span
                    >
                </div>
                <div>
                    <span
                        id="desc_yes_no_exist_439"
                        class="text-gray-400 mt-2 fw-bold fs-6 span-text description content-editable"
                        contenteditable="true"
                        @input="(event) => onInput(event, 'description')"
                        @click="checkInput('description')"
                        @blur="setValue('description')"
                        >{{ question.description }}</span
                    >
                </div>
            </h3>
            <div class="div-top-icon show-on-hover">
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Scoring"
                    v-if="pointStatus == 1"
                    @click="sendActiveToMain(5)">
                    <span class="svg-icon svg-icon-3 show-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" :class="checkScoring(question) == true ? 'text-success' : ''" viewBox="0 0 384 512" fill="#a1a5b7">
                                <path d="M374.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-320 320c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l320-320zM128 128A64 64 0 1 0 0 128a64 64 0 1 0 128 0zM384 384a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/>
                            </svg>
                    </span>
                </a>
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    @click="sendActiveToMain(2)"
                    data-bs-original-title="Scoring"
                >
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <i class="fas fa-flag" :class="checkScoring(question) == true ? 'text-success' : ''"></i>
                    </span>
                </a>
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    @click="sendActiveToMain(4)"
                    data-bs-original-title="Jump Logic"
                >
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <i class="fa fa-cogs fa-lg" :class="checkJumpLogic(question) == true ? 'text-success' : ''"></i>
                        <!-- <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 492 492" style="enable-background:new 0 0 492 492;" xml:space="preserve"><g><g><path style="fill:#B7B8C5;" d="M347.384,78c10.668,0,19.668-3.833,27-11.5c7.334-7.667,11-16.833,11-27.5s-3.666-19.833-11-27.5c-7.332-7.667-16.332-11.5-27-11.5s-19.834,3.833-27.5,11.5c-7.666,7.668-11.5,16.835-11.5,27.5c0,10.667,3.834,19.834,11.5,27.5C327.552,74.167,336.718,78,347.384,78z"/><path style="fill:#B7B8C5;" d="M184.384,235l138-90c8.668-6,14.168-14.167,16.5-24.5c2.332-10.335,0.666-20.168-5-29.5c-5.666-9.334-13.667-15.167-24-17.5c-10.332-2.333-19.832-0.5-28.5,5.5l-102,66c-6,4-10.333,9-13,15l-106,211c-3.333,6.667-4,13.167-2,19.5s6.167,11.167,12.5,14.5c6.333,3.333,12.667,3.833,19,1.5s11.167-6.5,14.5-12.5L184.384,235z"/><path style="fill:#B7B8C5;" d="M453.384,220c8.668-5.333,14.334-13.333,17-24c2.668-10.667,1-20.667-5-30l-13,8c3.334,6,4.334,12.167,3,18.5c-1.332,6.333-5,11.167-11,14.5l-424,271l9,14L453.384,220z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> -->
                    </span>
                </a>
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm me-4"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                     @click="sendActiveToMain(3)"
                    data-bs-original-title="Tagging"
                >
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <i class="fa fa-tag fa-lg" :class="checkTagging(question) == true ? 'text-success' : ''"></i>
                    </span>
                </a>
                <a
                    href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    @click="removeQuestion"
                    data-bs-original-title="Delete Card"
                >
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"
                            ></path>
                            <path
                                opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"
                            ></path>
                            <path
                                opacity="0.5"
                                d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"
                            ></path>
                        </svg>
                    </span>
                </a>
            </div>
            <div class="div-bottom-menu show-on-hover">
                  <label class="form-check required-checkbox p-2 me-9" for="hidden_checkbox"><input v-model="question.is_hidden" type="checkbox"
                        class="form-check-input is_required" name="Checkboxes1" id="hidden_checkbox" @change="update_hidden(mainIndex)"/><span></span>Hidden</label>
                <label class="form-check required-checkbox p-2">
                    <input
                        type="checkbox"
                        class="form-check-input is_required"
                        name="Checkboxes1"
                        v-model="question.is_required"
                        @change="up"
                    />
                    <span></span>Required</label
                >
            </div>
        </div>
        <div class="card-body pt-5">
            <!-- <pre class="text-dark">score {{ checkScoring(question) }}</pre>
            <pre class="text-dark">tag {{ checkTagging(question) }}</pre> -->
            <!-- <pre class="text-dark">{{ question }}</pre> -->
            <div
                class="form-group row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 g-10"
            >
                <div v-for="(i, iindex) in question.inputs" :key="iindex">
                    <button
                        class="btn w-100 h-20 span-text cursor-text cust-yn-btn"
                        :class="iindex == 0 ? 'yes_btn' : 'no_btn'"
                    >
                        <span
                            @input="
                                (event) =>
                                    updateYesOrnot(
                                        event,
                                        iindex,
                                        iindex == 0 ? 'yes' : 'no',
                                        i
                                    )
                            "
                            contenteditable="true"
                            style="
                                outline: transparent solid 0px;
                                /* background-color: rgb(255, 255, 255); */
                            "
                            >{{ i.field_value }}</span
                        >
                    </button>
                </div>
            </div>
        </div>
        <img
            class="draggable-handle div-left-icon show-on-hover"
            :src="require('@/assets/images/img/order_drag.svg')"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title=""
            data-bs-original-title="Drag to change order"
            aria-label="Drag to change order"
        />
        <h1 class="div-left-hd show-on-hover left-count-pos-40 id">{{aIndex}}</h1>
    </div>
</template>
<script>
import _ from "lodash";
import { defineComponent,toRefs} from "vue";
import {mapActions} from "vuex"
import QuizMixin from '@/mixin/question.js'
export default defineComponent({
    mixins:[QuizMixin],
    emits: ["removequestion","updateMainArray","setActiveQuestion","updateHidden"],
    name: "yes-no",
    props: {
        questionData: {
            type: Object,
        },
        mainIndex: {
            type: Number,
        },
        scoreStatus: {
            type : Number
        }
    },
    setup(props) {
        const { questionData: question } = toRefs(props);
        const { mainIndex: pindex } = toRefs(props);
        const { scoreStatus : pointStatus } = toRefs(props);
        return {
            question,
            pindex,
            pointStatus
        };
    },
    data(){
        return {
            aIndex:this.mainIndex+1
        }
    },
    methods: {
         ...mapActions({
            updateInputValue:'Quiz/addInputValue',
        }),
        updateYesOrnot(event, index,type,ia) {
            if (type == "yes") {
                if (event.target.innerText) {
                    this.question.inputs[index].field_label =
                        event.target.innerText;
                    this.question.inputs[index].field_value =
                        event.target.innerText;
                } 
                else {
                    this.question.inputs[index].field_label = "Yes";
                    this.question.inputs[index].field_value = "Yes";
                }
            } else {
                if (event.target.innerText) {
                    this.question.inputs[index].field_label =
                        event.target.innerText;
                    this.question.inputs[index].field_value =
                        event.target.innerText;
                } else {
                    this.question.inputs[index].field_label = "No";
                    this.question.inputs[index].field_value = "No";
                }
            }
             this.UpdateInputValueI(ia)
              this.sendupdatedArray()
        },
            UpdateInputValueI: _.debounce(function (q) {
                 let data={
                    ...q,
                    type:this.question.type
                }
            this.updateInputValue(data)
        }, 500),
        removeQuestion(){
                  let data={
                      index:this.pindex,
                      id:this.question.db_id
                  }
                    this.$emit('removequestion',data)
        },
        sendupdatedArray(){
                let data={
                    question:this.question,
                    index:this.pindex
                }
                this.$emit('updateMainArray',data)
            },
            updateValue(d){
                console.log(d,'ddd')
            }
    },
});
</script>
<style scoped>
.div-bottom-menu{
    position: absolute;
    right: 5px;
    /* top: 8px; */
    bottom: 8px;
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}
.div-top-icon{
    position: absolute;
    right: 5px;
    top: 8px;
    /* bottom: 8px; */
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}

.card:hover  .title-div{
    width: 85% !important;
    word-break: break-word;
}
.btn.btn-bg-light{
 background-color: #f5f8fa;
}
.text-dark.span-text{
    color: #181c32!important;   
}
.form-select, .form-control{
    background-color: #fff;
    border: 1px solid #e4e6ef;
    color: #181c32!important;   
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus{
    background-color: #f5f8fa !important;
    border-color: #f5f8fa !important;
    color: #181c32!important;   
}
/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label{
    color: #181c32;
}
.col-form-label, .form-label{
  color: #3f4254;
}
</style>

