<template>
    <div class="card q pb-9" :class="!question.db_id ? 'pointer-event-none' : ''">
           <div class="header-top"></div>
        <div class="card-header align-items-center border-0 splash-header">
            <h3 class="card-title">
                <div class="info_card">
                    <span id="ques_info_2" class="fw-bolder text-black fs-3 span-text question"
                    @click="checkInput('question')"
                   >Multi Splash</span>
                </div>
            </h3>
            <div class="div-top-icon show-on-hover">
                <a href="javascript:void(0)"
                    class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                    data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete Card"
                    @click="removeQuestion()">
                    <span class="svg-icon svg-icon-3 show-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                fill="black"></path>
                            <path opacity="0.5"
                                d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                fill="black"></path>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                fill="black"></path>
                        </svg>
                    </span>
                </a>
            </div>
            <!-- <div class="div-bottom-menu show-on-hover">
                       <label class="form-check required-checkbox p-2">
                    <input type="checkbox" class="form-check-input is_required" name="Checkboxes1" @change="up" v-model="question.is_required" />
                    <span></span>Required</label>
            </div> -->
        </div>
        <div class="card-body">
            <!-- <pre class="text-primary">{{question}}</pre> -->
            <div
                class="form-group row g-10">
                <div v-for="(q,index) in question.inputs" :key="index" class="col-6">
                    <span id="first_label_exist" class="form-label fw-bolder">Answer: {{q.field_label}}</span>
                    <input id="first_input_exist" :type="q.type" class="form-control form-control-solid" @input="(event) => UpdateInputValueI(q)" v-model="q.field_value" />
                </div>
                <div class="col-2">
                    <span id="message_duration" class="form-label fw-bolder">Message Duration</span>
                    <input id="message_duration" type="number" class="form-control form-control-solid"  @input="updateQuestionMessageDuration" v-model="question.message_duration" />
                </div>
                <!-- <div v-for="(input,index) in previousQuestion.inputs" :key="index" class="col-6">
                    <span id="first_label_exist" class="form-label fw-bolder">Answer: {{input.field_value}}</span>
                    <input id="first_input_exist" :type="input.type" class="form-control form-control-solid" @input="(event) => UpdateInputValueI(input)" :value="inputFieldValue(input)" />
                </div> -->
            </div>
        </div>
        <img class="draggable-handle div-left-icon show-on-hover" :src="require('@/assets/images/img/order_drag.svg')"
            data-bs-toggle="tooltip" data-bs-placement="left" title="Drag to change order" />
        <h1 class="div-left-hd show-on-hover left-count-pos-40 id">{{aIndex}}</h1>
    </div>
</template>
<script>
    import QuizMixin from '@/mixin/question.js'
    import { defineComponent, toRefs } from "vue";
    import _ from "lodash";
    import {mapActions} from 'vuex'
    export default defineComponent({
        mixins:[QuizMixin],
        name: "multi-spalsh",
        props: {
            questionData: {
                type: Object,
            },
            mainIndex: {
                type: Number,
            },
            questionTypes: {
                type: Array,
            },
        },
        setup(props) {
            const { questionData: question } = toRefs(props);
            const { mainIndex: pindex } = toRefs(props);
            const { questionTypes: questionTypesData } = toRefs(props);
            return {
                question,
                pindex,
                questionTypesData
            };
        },
        data(){
            return {
                aIndex:this.mainIndex+1,
                previousIndex : this.mainIndex-1,
            }
    },
    computed :{
        previousQuestion(){
            return this.questionTypesData[this.previousIndex]
        }
    },
    mounted(){
        console.log('questionTypesData',this.questionTypesData)
        console.log('previousQuestion',this.previousQuestion)
        this.setInputs()
    },
    watch:{
        previousQuestion(){
            console.log('changes')
        }
    },
    methods: {
        ...mapActions({
            updateInputValue:'Quiz/addInputValue',
        }),
        // updateSingleInput(event,index,d){
        //     console.log('')
        //         if (event.target.innerText) {
        //             console.log('indidfe')
        //             this.question.inputs[index].field_value = event.target.innerText;
        //         } else {
        //             this.question.inputs[index].field_value ="Thank you so much! [first]... This quiz is really going to help you!";
        //         }
        //     this.sendupdatedArray();
        //     this.UpdateInputValueI(d)
        // },
        UpdateInputValueI: _.debounce(function (q) {
            console.log(q)
            let data={
                ...q,
                type:this.question.type
            }
            this.sendupdatedArray()
            this.updateInputValue(data)
        }, 500),
        removeQuestion(){
            let data={
                index:this.pindex,
                id:this.question.db_id
            }
            this.$emit('removequestion',data)
        },
        sendupdatedArray() {
            let data = {
                question: this.question,
                index: this.pindex,
            };
            this.$emit("updateMainArray", data);
        },
        setInputs(){
            if(this.previousQuestion && (this.previousQuestion.question_type == 'options-question' || this.previousQuestion.question_type == 'dropdown-question' || this.previousQuestion.question_type == 'yes-no-question')){
                var previousInputs = this.previousQuestion.inputs
                if(this.question.inputs && this.question.inputs.length == 0){
                    previousInputs.map((input)=>{
                        let inputsData = {
                            db_id: "",
                            previous_input_id : input.db_id,
                            field_label: input.field_value,
                            field_value: `Here update splash response of ${input.field_value}`,
                            html_tag_type: "input",
                            id: 1,
                            type: "text",
                        } 
                        this.question.inputs.push(inputsData)
                        // console.log('input',input)
                    })
                }
                 console.log('this.question.inputs',this.question.inputs)
            }
        },
        inputFieldValue(input){
            // console.log('qinput',this.question.inputs)
            var value = this.question.inputs.map(inp => inp.id == input.previous_input_id)
            console.log('inn',input)
            console.log('value',value)
            if(value){
                return value.field_value
            }
            return null
        }
    },
});
</script>

<style scoped>
.div-bottom-menu{
    position: absolute;
    right: 5px;
    /* top: 8px; */
    bottom: 8px;
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}
.div-top-icon{
    position: absolute;
    right: 5px;
    top: 8px;
    /* bottom: 8px; */
    /* background-color: #FFFFFF; */
    padding-left: 35px;
}

.card:hover  .title-div{
    width: 80% !important;
    word-break: break-word;
}
.btn.btn-bg-light{
 background-color: #f5f8fa;
}
.text-dark.span-text{
    color: #181c32!important;   
}

/* .form-check-input{
    background-color: #fff;
} */
.div-bottom-menu label{
    color: #181c32;
}
.col-form-label, .form-label{
  color: #3f4254;
}
.form-select, .form-control{
    background-color: #fff;
    border: 1px solid #e4e6ef;
    color: #181c32!important;   
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus{
    background-color: #f5f8fa !important;
    border-color: #f5f8fa !important;
    color: #181c32!important;   
}
.splash-header{
    min-height: 50px !important;
}
.header-top{
    background-color: #E38600 !important;
    border-radius: 10px 10px 0px 0px;
    height: 8px;
}
</style>