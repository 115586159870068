import _ from "lodash";
import { mapActions } from "vuex";
export default {
    methods: {
        ...mapActions({
            updateRequire:'Quiz/updateRequire',
            updateMultiple:'Quiz/updateMultiple',
            updateHidden:'Quiz/updateHidden',
            updateNameandDes:'Quiz/updateNameandDes',
            updateMessageDuration:'Quiz/updateMessageDuration',
            updateCompletePercentage:'Quiz/updateCompletePercentage'
        }),
        onInput(event,type) {
            if(type=='description'){
                if(event.target.innerText){
                    this.question.description=event.target.innerText
                }else{
                    this.question.description='Type a Description'  
                }
            }else{
                 if(event.target.innerText){
                        this.question.question=event.target.innerText
                    }else{
                        this.question.question='Type a Question'  
                    }
            }
            this.saveOnDataBase()
        },
        saveOnDataBase: _.debounce(function () {
            this.updateQuestionADes()
        }, 500),
        checkInput(type){
            console.log(this.question.description,'ddd')
            if(type=='question'){
                if(this.question.question=='Type a Question') this.question.question=''
            }else{
                if(this.question.description=='Type a Description') this.question.description=''
            }
        },
        updateQuestionADes(){
            let data={
                id:this.question.db_id,
                question:this.question.question,
                description:this.question.description
            }
            this.updateNameandDes(data)
        },
        up(){
            let data={
                is_required:this.question.is_required,
                id:this.question.db_id
            }
            this.updateRequire(data)
        },
        upMultiple(){
            let data={
                is_multiple:this.question.is_multiple,
                id:this.question.db_id
            }
            this.updateMultiple(data)
        },
        update_hidden(mainIndex){
            let data={
                is_hidden:this.question.is_hidden,
                id:this.question.db_id
            }
            let newdata={
                is_hidden:this.question.is_hidden,
                index:mainIndex
            }
               this.$emit("updateHidden",newdata);
            this.updateHidden(data)
        },
        setValue(type){
            if(type=='question'){
                if(this.question.question=='') this.question.question='Type a Question'
            }else{
                if(this.question.description=='') this.question.description='Type a Description'
            }
        },
        sendActiveToMain(type){
            let data = {
                question: this.question,
                type: type
            };
            this.$emit("setActiveQuestion",data);
        },
        updateQuestionMessageDuration: _.debounce(function () {
            let data={
                id: this.question.db_id,
                message_duration: this.question.message_duration,
            }
            this.updateMessageDuration(data)
        }, 700),
        updateQuestionCompletePercentage: _.debounce(function () {
            let data={
                id: this.question.db_id,
                complete_percentage: this.question.complete_percentage,
            }
            this.updateCompletePercentage(data)
        }, 700),
        checkScoring(question){
            var score  = question.inputs && question.inputs.filter(inp => inp.disqualify == true)
            return  score && score.length > 0 ? true : false
        },
        checkTagging(question){
            var tag = question.inputs && question.inputs.filter(inp => inp.tag_id)
            return tag && tag.length > 0 ? true : false
        },
        checkJumpLogic(question){
            var jump = question.jump_logics && question.jump_logics.filter(logic => logic.jump_question_id)
            return jump && jump.length > 0 ? true : false
        },
    }
  };