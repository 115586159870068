<template>
    <div id="jump-logic-box" class="card card-xl-stretch mb-5 mb-xl-10 scr-tag-div-fixed jump-card">
        <div class="card-header">
            <div class="d-flex flex-wrap gap-1">
                <h3 class="card-title fw-bolder align-items-start flex-column me-2">Jump Logic
                </h3>
                <img :src="require('@/assets/images/img/question_mark_icon.svg')">
            </div>
            <div class="card-toolbar">
                   <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 me-3">
                        <input class="custom-checked form-check-input w-40px h-25px" type="checkbox" @change="updateJumpStatusData" v-model="is_jump_logic" id="add-intro-page">
                    </div>
                <span class="svg-icon svg-icon-primary svg-icon-2x show-pointer"
                    @click="$emit('clearSelected')">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                        height="24px" viewBox="0 0 24 24" stroke="none" version="1.1">
                        <g stroke="#A1A5B7" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                                fill="#A1A5B7">
                                <rect x="0" y="7" width="16" height="2" rx="1"></rect>
                                <rect opacity="0.3"
                                    transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                                    x="0" y="7" width="16" height="2" rx="1"></rect>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
        <div class="card-body py-3">
            <!-- <pre class="text-dark">q.jump  {{typeof this.question.is_jump_logic === 'undefined'}}</pre> -->
                <div v-for="(answer,index) in logic_array.logic_answer" :key="index">
                    <div class="row mb-4">
                        <label class="col-lg-12 col-form-label fw-bold fs-6 d-flex justify-content-between align-items-center">
                            <span>Is Answer is</span>
                            <a
                                href="javascript:void(0)"
                                class="btn btn-icon btn-bg-light btn-circle btn-active-color-primary btn-sm"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title=""
                                @click="removeJumpLogic(answer,index)"
                                data-bs-original-title="Delete Jump Logic"
                            >
                                <span class="svg-icon svg-icon-3 show-pointer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                            fill="black"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                            fill="black"
                                        ></path>
                                        <path
                                            opacity="0.5"
                                            d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                            fill="black"
                                        ></path>
                                    </svg>
                                </span>
                            </a>
                        </label>
                        <div class="col-lg-12">
                            <select name="country" @change="saveJumpLogicData" class="form-select form-select-solid fw-bold select2-hidden-accessible" v-model="answer.input_id">
                                <option :value="null">Select Answer</option>
                                <option v-for="(input,iindex) in question.inputs" :key="iindex" :value="input.id" :disabled="disableOption('answer',input)">{{input.field_label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <label class="col-lg-5 col-sm-12 col-md-12 col-form-label fw-bold fs-6">Go to question:</label>
                                <div class="col-lg-7 col-sm-12 col-md-12">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex mt-3">
                                            <span class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input w-20px h-20px" type="radio" :id="'all_question_'+index" @change="saveJumpLogicData" v-model="answer.question_type" value="all">
                                                <label class="form-label m-0 ms-4 me-4" :for="'all_question_'+index">
                                                    Question
                                                </label>
                                            </span>

                                            <span class="form-check form-check-custom form-check-solid">
                                                <input class="form-check-input w-20px h-20px" type="radio"
                                                    v-model="answer.question_type" :id="'hidden_question_'+index" @change="saveJumpLogicData" value="hidden">
                                                <label class="form-label m-0 ms-4 me-4" :for="'hidden_question_'+index">
                                                    Hidden
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <select class="form-select-solid form-select fw-bold select2-hidden-accessible" @change="saveJumpLogicData" v-model="answer.jump_question_id">
                                <option :value="null">Select Question</option>
                                <option v-for="(que,qindex) in (answer.question_type == 'all' ? allQuestions : hiddenQuestions)" :key="qindex" :value="que.id"  :disabled="disableOption('question',que)">{{que.question}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="separator separator-solid mt-8"></div>
                </div>
                <div class="text-end mt-5 text-dark fw-bold cursor-pointer text-gray-800" @click="addJumpLogic">Add New Logic</div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import { defineComponent, toRefs } from "vue";
import question from '@/mixin/question';
import _ from "lodash";
export default defineComponent({
    name: "JumpLogic",
    props: {
        data: {
            type: Object,
        },
    },
    setup(props) {
        const { data: question } = toRefs(props);
        return {
            question,
        };
    },
    computed: {
        ...mapGetters({
            getAllQuestion: 'Quiz/getAllQuestion',
            getallJumpData:'Quiz/getallJumpData',
        }),
        allQuestions(){
            return this.getAllQuestion.filter(question => question.id != this.question.id && question.is_hidden == 0)
        },
        hiddenQuestions(){
            return this.getAllQuestion.filter(question => question.id != this.question.id && question.is_hidden == 1)
        }
    },
    watch:{
        question(){
            this.getallQuestionAction(this.quiz_id)
            this.setJumpLogicData()
        }
    },     
    data() {
        return {
            isedit:false,
            edit_index:0,
            quiz_id: this.$route.params.id,
            jump_main_array:[],
            next_question_array:[
                {
                    label:'Go To Next Question',
                    id:1
                },
                {
                    label:'Go To Question #',
                    id:2
                },
                {
                    label:'End Quiz',
                    id:3
                }
            ],
            logic_array : {
                quiz_id:this.$route.params.id,
                question_id: this.question.id,
                logic_answer:[
                    { 
                        id: null,
                        unique_id: Math.floor(Math.random() * Date.now()),
                        input_id: null,
                        question_type: 'all',
                        jump_question_id: null
                    }
                ]
            },
            is_jump_logic : typeof this.question.is_jump_logic !== 'undefined' ? !!this.question.is_jump_logic : true
        }
    },
    methods:{
        ...mapActions({
            getallQuestionAction:'Quiz/getallQuestionAction',
            saveJumpLogic:'Quiz/saveJumpLogic',
            deleteJumpLogic:'Quiz/deleteJumpLogic',
            updateJumpStatus:'Quiz/updateJumpStatus'
        }),
        addJumpLogic(){
            if(this.logic_array.logic_answer.length){
                this.logic_array.logic_answer.push({
                    id: null,
                    unique_id: Math.floor(Math.random() * Date.now()),
                    input_id: null,
                    question_type: 'all',
                    jump_question_id: null
                })
            }
        },
        removeJumpLogic(ans,index){
            console.log('ans',ans)              
            this.logic_array.logic_answer.splice(index,1)
            this.deleteJumpLogic(ans.id)
        },
        saveJumpLogicData: _.debounce(function () {
            this.storeJumpLogic()
        }, 0),
        storeJumpLogic(){
            this.saveJumpLogic(this.logic_array).then((res)=>{
                if(res.data.status==true){
                    var jumpLogicData = res.data.data
                    if(jumpLogicData.length > 0){
                        jumpLogicData.map((jump)=>{
                            if(jump.unique_id){
                                let index = this.logic_array.logic_answer.findIndex((ans) => ans.unique_id == jump.unique_id);
                                this.logic_array.logic_answer[index].id= jump.id
                            }
                        })
                    }
                    // this.logic_array.logic_answer = jumpLogicData
                    this.question.jump_logics = jumpLogicData
                }
            })
        },
        setJumpLogicData(){
            console.log('set logic data')
            if(this.question.jump_logics && this.question.jump_logics.length > 0){
                this.logic_array = {
                    quiz_id:this.$route.params.id,
                    question_id: this.question.id,
                    logic_answer: this.question.jump_logics
                }
            }else{
                this.logic_array = {
                    quiz_id:this.$route.params.id,
                    question_id: this.question.id,
                    logic_answer: [
                        { 
                            id: null,
                            unique_id: Math.floor(Math.random() * Date.now()),
                            input_id: null,
                            question_type: 'all',
                            jump_question_id: null
                        }
                    ]
                }
            }
            if(typeof this.question.is_jump_logic === 'undefined'){
                this.is_jump_logic = true
                this.question.is_jump_logic = 1
            }else{
                this.is_jump_logic = !!this.question.is_jump_logic
            }
        },
        disableOption(type,data){
            if(type == 'answer'){
                return this.logic_array.logic_answer.find(ans => ans.input_id == data.id) ? true : false
            }else{
                return this.logic_array.logic_answer.find(ans => ans.jump_question_id == data.id) ? true : false
            }
        },
        updateJumpStatusData(){
            var payload = {
                question_id : this.question.id,
                is_jump_logic : this.is_jump_logic,
            }
            this.question.is_jump_logic = this.is_jump_logic ? 1 : 0
            this.updateJumpStatus(payload)
        }
    },
    mounted(){
        this.getallQuestionAction(this.quiz_id)
        this.setJumpLogicData()
    },
});
</script>
<style scoped>
.jump-card{
    min-height: calc(100vh - 300px);
}
</style>